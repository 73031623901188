export const filterByInclude =
  "https://us-central1-farmxr-a77b1.cloudfunctions.net/filterByContain";



//@description - Contains the details of the database in firebase that the application connects to

// export const firebaseConfig = {
//   apiKey: "AIzaSyDZqVYWXNr94TN9qtm7_J8RnghEJjzLoPY",
//   authDomain: "smartag-60b70.firebaseapp.com",
//   databaseURL: "https://smartag-60b70.firebaseio.com",
//   projectId: "smartag-60b70",
//   storageBucket: "smartag-60b70.appspot.com",
//   messagingSenderId: "637417933081",
//   appId: "1:637417933081:web:5f8c0f75294531c4aedd88"
// }


 export const firebaseConfig = {
   apiKey: "AIzaSyBZohXi8KRqp0GObKP4BO7fzFLAB763QVw",
   authDomain: "farmxr-a77b1.firebaseapp.com",
   databaseURL: "https://farmxr-a77b1.firebaseio.com",
   projectId: "farmxr-a77b1",
   storageBucket: "farmxr-a77b1.appspot.com",
   messagingSenderId: "508872751380",
   appId: "1:508872751380:web:3372f6bcb1fb472a28aa01",
   measurementId: "G-MHY3LJ4YX3",
 };

 // export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/smartag-60b70.appspot.com/o/'
 export const storageUrl =
   "https://firebasestorage.googleapis.com/v0/b/farmxr-a77b1.appspot.com/o/";
