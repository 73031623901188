import 'styled-components'
import { DefaultTheme } from 'styled-components'

declare module 'styled-components' {
    export interface DefaultTheme {
        borderRadius: string,
        padding: string,
        gridGap: string,
        sideMargins: string,
        minSize: string,
        lineSize: string,

        colors: {
            primary: string,
            alternative: string,
            light: string,
            darkLight: string,
            dark: string,
            medium: string
        },

        fontSize: {
            title: string,
            subTitle: string,
            text: string
        }
    }
}

const Theme: DefaultTheme = {
    borderRadius: '.5rem',
    padding: '1rem',
    gridGap: '1rem',
    sideMargins: '.6rem',
    minSize: '2.5rem',
    lineSize: '.05rem',

    colors: {
        primary: '#5288ac',
        alternative: '#1f387a',
        light: '#ffffff',
        darkLight: '#f5f5f5',
        dark: '#111111',
        medium: '#414141'
    },
    fontSize: {
        title: '2rem',
        subTitle: '1.5rem',
        text: '1rem'
    }
}

export default Theme;