import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// Context.
import { RouterContext, Paths } from './context/RouterContext'
import { NotificationsContext } from './context/NotificationsContext'
import { PinMenuContext } from './context/PinMenuContext'
import { EditPinContext } from './context/EditPinContext'
import { AuthContext } from './context/AuthContext'
import { EventsContext } from './context/EventsContext'
import { ViewportContext } from './context/ViewportContext'
import { PopupContext } from './context/PopupContext'
import { PolygonsContext } from './context/PolygonsContext'
import { StoreContext } from './store'

// Pages.
import Home from './pages/Home'
import Settings from './pages/Settings'
import Users from './pages/Users'
import Login from './pages/Login'
import UIPlayground from './utility/UIPlayground'

// Overlays.
import Notifications from './components/Notifications'
import Popup from './components/Popup'
import Viewport from './components/Viewport'

/** 
 * @description Landing function for the entire application that is invoked inside the index.tsx page
 * @return React component that contains all information about the routes and paths along with all the contexts
 */
function App() {
    return (
        <Context>
            <Switch>
                <Route path={Paths.home} exact><Home /></Route>
                <Route path={Paths.settings}><Settings /></Route>
                <Route path={Paths.users}><Users /></Route>
                <Route path={Paths.login}><Login /></Route>

                <Route path={'/playground'}><UIPlayground /></Route>
            </Switch>

            <Viewport />
            <Notifications />
            <Popup />

        </Context>
    )
}

/**
 * @description List of all the components that are used inside the application
 * @param object children -  Components that can be added to the context
 * @return All the contextual components to the App function which in turn is rendered inside container function in index.tsx
 */
function Context({ children }) {
    return (
        <Router>
        <EventsContext>
        <RouterContext>
        <PolygonsContext>
        <NotificationsContext>
        <ViewportContext>
        <PopupContext>
        <StoreContext>
        <AuthContext>
        <PinMenuContext>
        <EditPinContext>
        { children }
        </EditPinContext>
        </PinMenuContext>
        </AuthContext>
        </StoreContext>
        </PopupContext>
        </ViewportContext>
        </NotificationsContext>
        </PolygonsContext>
        </RouterContext>
        </EventsContext>
        </Router>
    )
}

    export default App