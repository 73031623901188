import React, { useContext, useEffect, useState } from 'react'

import { getUrl } from '../firebase/storage'

import Router, { IRouter } from '../context/RouterContext'
import Notifications, { INotifications } from '../context/NotificationsContext'
import PinMenu, { IPinMenuState } from '../context/PinMenuContext'
import EditPin, { IEditPinState } from '../context/EditPinContext'
import Events, { IEvents } from '../context/EventsContext'
import Viewport, { IViewport } from '../context/ViewportContext'
import Auth, { IAuth } from '../context/AuthContext'
import Popup, { IPopup } from '../context/PopupContext'
import Polygons, { IPolygons } from '../context/PolygonsContext'
import Store, { IStore, IFarm, TRole } from '../store'
import { useHistory } from 'react-router-dom'

export function useSelectedFarm() {
    const router = useRouter()
    const store = useStore()
    const farmParamName = 'farm'
    const history = useHistory()

    interface IReturn {
        farm?: IFarm,
        farmId?: string,
        setFarm(farm: IFarm): void
    } 

    const [state, set] = useState<IReturn>({
        setFarm(farm) { 
            router.setParams(`${farmParamName}=${farm.id}`)
            set(prev => { return { ...prev, farm: farm, farmId: farm.id }})
        }
    })

    useEffect(() => {
        const farmId = router.getParam(farmParamName)
        let farm
        if (farmId && store.state.farms) farm = store.state.farms[farmId]
        set(prev => { return { ...prev, farm: farm, farmId: farmId }})
    }, [store.state.farms, history.location.search])

    return state 
}

export function useStore() {
    const store = useContext(Store) as IStore
    return store
}

export function useGetFarmRole() {
    const store = useStore()
    const selectedFarm = useSelectedFarm()
    const [role, setRole] = useState<TRole>('viewer')

    useEffect(() => {
        let value: TRole = 'viewer'
        try { if (store?.state?.user && selectedFarm.farm) value = selectedFarm.farm.roles[store.state.user.localId] } catch {}
        if (value) setRole(value)
    }, [store?.state?.user, selectedFarm.farm])

    return role
}

export function useViewport() {
    const viewport = useContext(Viewport) as IViewport
    return viewport
}

export function useEvents() {
    const events = useContext(Events) as IEvents
    return events
}

export function usePolygons() {
    const polygons = useContext(Polygons) as IPolygons
    return polygons
}

export function usePopup() {
    const popup = useContext(Popup) as IPopup
    return popup
}

export function useEditPin() {
    const editPin = useContext(EditPin) as IEditPinState
    return editPin
}

export function usePinMenu() {
    const pinMenu = useContext(PinMenu) as IPinMenuState
    return pinMenu
}

export function useRouter() {
    const router = useContext(Router) as IRouter
    return router
}

export function useAuth() {
    const auth = useContext(Auth) as IAuth
    return auth
}

export function useNotifications() {
    const notifications = useContext(Notifications) as INotifications
    return notifications
}

export function useAwakeBool() {
    const [state, set] = useState(false)
    useEffect(() => { if (!state) set(true) }, [state]) 
    return state
}

export function useUrl(path) {
    const [url, setUrl] = useState()

    useEffect(() => {
        if (path) getUrl(path).then(setUrl)
    }, [path])

    return url
}