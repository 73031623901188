import firebase from './'
import fetch from 'node-fetch'

import { filterByInclude } from '../brand'

const database = firebase.database()

export enum Paths {
    farms = 'farm',
    pinTypes = 'pinType',
    properties = 'property',
    roles = 'role',
    users = 'users'
}

export const getContent = (path: string) => new Promise(async (resolve, reject) => {
    try {
        const snapshot = await database.ref(path).once('value')
        resolve(snapshot.val())
    } catch(error) { reject(error) }
})

export const subscribeToContent = (path: string, callback: (value: any) => void) => {
    return database.ref(path).on('value', snapshot => {
        if(snapshot) callback(snapshot.val())
    })
}

export const getFiltered = (path: string, filter: string, value: any) => new Promise(async (resolve, reject) => {
    try {
        const snapshot = await database.ref(path).orderByChild(filter).equalTo(value).once('value')
        resolve(snapshot.val())
    } catch(error) { reject(error) }
})

// Get from database, filtered by "includes".
export function filterByIncludes(dictionaryPath: string, subDictionaryPath: string, keys: string[]): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            fetch(filterByInclude, { 
                method: 'POST', 
                body: JSON.stringify({
                    dictionaryPath: dictionaryPath,
                    subDictionaryPath: subDictionaryPath,
                    keys: keys
                }),
                headers: { 'Content-Type': 'application/json' }
            })
            .then(res => res.json())
            .then(json => resolve(json))
        } catch(error) { reject(error) }
    }) 
}

export const setContent = (path: string, payload: any) => new Promise(async (resolve, reject) => {
    try {
        if (path) { 
            const snapshot = await database.ref(path).set(payload)
            resolve(snapshot)
        }
        else reject('null data')
    } catch(error) { reject(error) }
})

export const onDisconnect = (path: string, value: any) => {
    if (path && value != null) database.ref(path).onDisconnect().set(value)
}