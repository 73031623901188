import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { uploadImage } from '../../firebase/storage'

import { key } from '../../utility'
import Icons from '../../graphics/icons'
import { logEvent } from '../../firebase/analytics'

import { InputField, Button, Text, Image, SVG, FileSelector } from '../../components/UI'

import { useEditPin, usePinMenu, useEvents, usePopup, useStore, useNotifications, useGetFarmRole, useSelectedFarm } from '../../hooks'

import { IPin } from '../../store'

const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
`

const AddButton = styled.div`
    position: absolute;
    left: ${p => p.theme.sideMargins};
    top: 50%;
    transform: translateY(-50%);
    pointer-events: all;
`

const BackButton = styled.div`
    position: absolute;
    left: ${p => p.theme.sideMargins};
    top: ${p => p.theme.padding};
    pointer-events: all;
`

const EditPanelContainer = styled.div<{show: boolean}>`
    position: absolute;
    top: calc(${p => p.theme.padding} + ${p => p.theme.minSize} + ${p => p.theme.padding});
    right: ${p => p.show ? p.theme.padding : '-22rem'};
    opacity: ${p => p.show ? 1 : 0};
    width: 20rem;
    transition: .3s;
    pointer-events: all;
`

const ButtonBlock = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

const EditInfoContainer = styled.div`
    border-radius: ${p => p.theme.borderRadius};
    background-color: ${p => p.theme.colors.light};
    display: grid;

    max-height: 60vh;
    overflow: auto;
    overflow-x: hidden;
`

const EditInfoTopBar = styled.div`
    background-color: ${p => p.theme.colors.primary};
    padding: ${p => p.theme.padding};
    display: grid;
    grid-template-columns: ${p => p.theme.minSize} auto;
`

const EditInfoWhite = styled.div`
    background-color: ${p => p.theme.colors.light};
    padding: ${p => p.theme.padding};

    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

const FloatingButton = styled.div<{show?: boolean, second?: boolean}>`
    position: absolute;
    bottom: -${p => p.theme.gridGap};
    transform: translateY(${p => p.second ? '230%' : '100%'});
    right: ${p => p.show ? 0 : '-100%'};
    opacity: ${p => p.show ? 1 : 0};
    transition: .3s;
`

const ConfirmPositionButton = styled.div<{show?: boolean}>`
    position: absolute;
    bottom: ${p => p.theme.gridGap};
    right: ${p => p.show ? p.theme.gridGap : '-100%'};
    opacity: ${p => p.show ? 1 : 0};
    transition: .3s;
    pointer-events: all;
`

const InputRow = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-auto-flow: column;
`

const TypeButton = styled.div<{alternative?: boolean}>`
    padding: 2rem;
    background-color: ${p => p.alternative ? p.theme.colors.alternative : p.theme.colors.primary};
    display: grid;
    grid-template-columns: 3rem auto;
    grid-template-rows: 3rem;
    border-radius: ${p => p.theme.borderRadius};
    grid-gap: ${p => p.theme.gridGap};
    cursor: pointer;
    :hover {
        filter: brightness(120%);
    }
`

const ImageContainer = styled.div`
    height: 8rem;
`

interface IEditPin {
    selectedImage?: any,
    loading: boolean,
}

export default function EditPin() {
    const events = useEvents()
    const farmRole = useGetFarmRole()
    const editPin = useEditPin()
    const pinMenu = usePinMenu()
    const store = useStore()
    const selectedFarm = useSelectedFarm()
    const notifications = useNotifications()
    const popup = usePopup()
    const [state, set] = useState<IEditPin>({
        loading: false
    })

    // Create new pin, and start editing.
    function addHandler(): void { 
        editPin.setPin({ id: key('pin') }, 'position') 
        logEvent('Create Pin')
    }

    // Handle back button's logic.
    function backHandler(): void {
        if (editPin.firstView === editPin.view || editPin.view === 'position') editPin.close()
        else if (editPin.view === 'type') editPin.setView('position') 
        else if (editPin.view === 'data') editPin.setView('type')
    }

    // Confirm pin data
    function confirmPin(): void { 
        editPin.storePin()
        editPin.close()
        console.log("Pressed confirm pin")
    }

    function setPinType(type): void {
        editPin.setPinType(type)
        editPin.setView('data')
    }

    const typeData = {
        'note': { icon: Icons.note, name: 'Notes' },
        'task': { icon: Icons.task, name: 'Task' },
        'sensor': { icon: Icons.sensor, name: 'Sensor' },
        '360image': { icon: Icons.image360, name: '360 Image' },
        'web': { icon: Icons.web, name: 'Web' },
    }

    function getTypeIcon(): string { try { return typeData[editPin.pin.type].icon } catch {} }

    function getTypeName(): string { try { return typeData[editPin.pin.type].name } catch {} }

    function isNew(): boolean { return selectedFarm.farm?.pins && !(editPin?.pin?.id in selectedFarm.farm?.pins)}

    function confirmPinPositionHandler(): void { 
        if (editPin.firstView === 'data') editPin.setView('data')
        else editPin.setView('type') 
    }

    function deleteHandler(): void {
        popup.askForConfirmation(`Delete ${editPin.pin.name ? editPin.pin.name : 'Pin'}?`,
        () => {
            editPin.deletePin()
            editPin.close()
        }, () => {})
    }

    async function uploadImageHandler(): Promise<void> {
        const image = state.selectedImage
        set(prev => { return {...prev, selectedImage: null, loading: true}})
        try {
            const id = await uploadImage(image) as string
            editPin.editPin({ ...editPin.pin, image: id })
            set(prev => { return {...prev, loading: false}})
        } catch (error) {
            set(prev => { return {...prev, loading: false}})
            notifications.notify(error, true)
        }
    }

    useEffect(() => {
        const unsubscribe = events.on('map-click', data => {
            // Add a custom Pin and position pin in map.
            // Fill data -> store in db. OK stored -> Create the Pin.
            // Currently using active bool, maybe subscribe | unsubscribe when needed?
            if (editPin.active && editPin.view === 'position') editPin.updatePinLocationData(data.lat, data.lng) 
        })

        return () => unsubscribe()

    }, [editPin])

    function getSensorDataFields() {
        const list = [
            <Text key='title' dark bold middle>Sensor Data</Text>,
            <InputField 
                key='sensorApiUrl'
                value={editPin.pin?.sensorData?.sensorApiUrl}
                onChange={v => editPin.setPinSensorData({...editPin.pin?.sensorData, sensorApiUrl: v})}
                placeholder='Sensor API URL'
            />,
            <InputField 
                key='sensorSupplierWeb'
                value={editPin.pin?.sensorData?.sensorSupplierUrl}
                onChange={v => editPin.setPinSensorData({...editPin.pin?.sensorData, sensorSupplierUrl: v})}
                placeholder='Sensor Supplier URL'
            />,
        ]

        return list
    }

    function isViewportType() { return editPin.pin?.type === '360image' || editPin.pin?.type === 'web' }
    function getViewportFields() {
        const list = [
            <Text key='title' dark bold middle>Viewport Data</Text>,
            <InputField 
                key='viewportURL'
                value={editPin.pin?.viewportData?.url}
                onChange={v => editPin.setPinViewportData({ ...editPin.pin?.viewportData, url: v})}
                placeholder='Viewport embeded URL'
            />,
            <InputField 
                key='viewportTitle'
                value={editPin.pin?.viewportData?.title}
                onChange={v => editPin.setPinViewportData({ ...editPin.pin?.viewportData, title: v})}
                placeholder='Viewport Title'
            />,
        ]

        return list
    }

    const typeButtons = [
        {type: 'todo', icon: Icons.task, name: 'Task'},
        {type: 'note', icon: Icons.note, name: 'Note'},
        {type: 'sensor', icon: Icons.sensor, name: 'Sensor'},
        {type: '360image', icon: Icons.image360, name: '360 Image'},
        {type: 'web', icon: Icons.web, name: 'Web'},
    ]

    return (
        <Container>
            { editPin.active ? <BackButton><Button light onClick={() => backHandler()} icon={Icons.back} /></BackButton> : farmRole ? <AddButton><Button light onClick={() => addHandler()} icon={Icons.add}>Add</Button></AddButton> : null}

            {farmRole != 'viewer' && editPin.active && 
            <EditPanelContainer show={( editPin.view === 'type' && editPin.pin != null)}>
                <ButtonBlock>
                    {typeButtons.map((data, i) => 
                        <TypeButton key={data.type} alternative={i%2 === 0} onClick={() => setPinType(data.type)}>
                            <SVG contain image={data.icon}/>
                            <Text middle bold>{data.name}</Text>
                        </TypeButton>
                    )}
                </ButtonBlock>
            </EditPanelContainer>}

            {farmRole != 'viewer' && editPin.active &&
                <ConfirmPositionButton show={editPin.view === 'position' && editPin?.pin?.longitude != null}>
                    <Button alternative onClick={() => confirmPinPositionHandler()}>Confirm Pin Position</Button>
                </ConfirmPositionButton> }

            {farmRole != 'viewer' && editPin.active &&
            <EditPanelContainer show={(editPin.view === 'data' && editPin.pin != null)}>
                <EditInfoContainer>
                    <EditInfoTopBar>
                        <SVG contain image={getTypeIcon()}/>
                        <Text subTitle>{getTypeName()}</Text>
                    </EditInfoTopBar>
                    <EditInfoWhite>
                        <InputField 
                            value={editPin.pin.name}
                            onChange={v => editPin.setPinName(v)}
                            placeholder='Name'
                        />
                        <InputField 
                            value={editPin.pin.description}
                            onChange={v => editPin.setPinDescription(v)}
                            placeholder='Description'
                        />
                        <div />
                        <Text bold  dark>Position</Text>
                        <InputRow>
                            <Text middle dark>Lat:</Text>
                            <InputField 
                                value={editPin.pin.latitude}
                                onChange={v => editPin.setPinLatitude(v)}
                                placeholder='Latitude'
                            />
                        </InputRow>
                        <InputRow>
                            <Text middle dark>Lng:</Text>
                            <InputField 
                                value={editPin.pin.longitude}
                                onChange={v => editPin.setPinLongitude(v)}
                                placeholder='Longitude'
                            />
                        </InputRow>

                        <Text bold dark>Other</Text>
                        <Button onClick={() => editPin.setView('position')}>Change Position</Button>
                        <Button onClick={() => editPin.setView('type')}>Change Type</Button>

                        <Text bold dark>Upload Image</Text>
                        {editPin.pin.image && <ImageContainer><Image url={editPin.pin.image}/></ImageContainer>}
                        <FileSelector 
                            fileHandler={f => set({...state, selectedImage: f})}
                        />
                        {state.selectedImage != null && <Button onClick={() => uploadImageHandler()}>Upload</Button>}

                        {editPin.pin.type === 'sensor' && getSensorDataFields()}

                        {isViewportType() && getViewportFields()}

                    </EditInfoWhite>
                </EditInfoContainer>
                <FloatingButton show={!isNew()}><Button alternative reverse icon={Icons.delete} onClick={() => deleteHandler()} left>Delete</Button></FloatingButton>
                <FloatingButton show={(editPin.pin.name != null && editPin.pin.name != '')} second={!isNew()}><Button reverse icon={Icons.check} onClick={() => confirmPin()} left>Done</Button></FloatingButton>
            </EditPanelContainer>}
        </Container>
    )
}