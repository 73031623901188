import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { key } from 'firebase-key'

import Map from '../map/'
import { Button, Text, SVG, InputField, Checkbox, FileSelector } from '../components/UI'
import Embed from '../components/Embed'
import { logEvent } from '../firebase/analytics'

import { useNotifications, useStore, useEditPin, useRouter, useAuth, useAwakeBool, usePopup, useGetFarmRole, usePolygons, useSelectedFarm, useViewport } from '../hooks'

import Icons from '../graphics/icons'
import getPinsFromKMZ from '../utility/kmzParser'

import { IFarm, IPin, Dictionary, TRole } from '../store'

import PinMenu from './Home/PinMenu'
import EditPin from './Home/EditPin'

import { Paths } from '../context/RouterContext'

const Container = styled.div`
    background-color: ${p => p.theme.colors.dark};
    height: 100vh;
`

type TFarmPanel = 'select' | 'create' | 'edit'

interface IHome {
    setFarm(f: IFarm): void,
    farmPanel?: TFarmPanel,
    setFarmPanel(v: TFarmPanel): void,
    save(): void
}

export default function Home() {
    const editPin = useEditPin()
    const store = useStore()
    const polygons = usePolygons()
    const notifications = useNotifications()
    const selectedFarm = useSelectedFarm()
    const [state, set] = useState<IHome>({
        setFarm(f) {
            selectedFarm.setFarm(f)
            logEvent('Select Farm')
            this.setFarmPanel(null)
        },
        setFarmPanel(v) {
            if (v === null || v === this.farmPanel) {
                this.farmPanel = null
                notifications.hide()
            }

            else {
                this.farmPanel = v

                if (v === 'select') notifications.notify('Choose or create a Farm', false)
                if (v === 'create') notifications.notify('Fill new farm details', false)
            }

            this.save()
        },
        save() { set({ ...this })}
    })

    // Show Farm panel if no farm is selected.
    useEffect(() => {
        if (store.state.farms && !selectedFarm.farmId && state.farmPanel != 'select') state.setFarmPanel('select')

        if (selectedFarm.farm) {
            polygons.clearAll()
            if (selectedFarm.farm.boundaries) {
              Object.values(selectedFarm.farm.boundaries).forEach((b) => {
                polygons.addPoligon(
                  b.paths.map((p) => {
                    return { lat: p.lat, lng: p.lng };
                  })
                );
              });
            }

            if (state.farmPanel === 'select') state.setFarmPanel(null)
        }
    }, [store.state.farms, selectedFarm.farm])

    return (
        <Container>
            <Map />

            {!editPin.active && <TopBar 
                farmHandler={() => state.setFarmPanel('select')}
                editFarmHandler={() => state.setFarmPanel('edit')}
                farm={selectedFarm.farm}
            />}

            {!editPin.active && selectedFarm.farm && <PinMenu />}

            {selectedFarm.farm && <EditPin />}

            {state.farmPanel === 'select' && <FarmPanel 
                farms={store.state.farms && Object.values(store.state.farms) as IFarm[]}
                farmClickHandler={f => state.setFarm(f)}
                createFarmHandler={() => state.setFarmPanel('create')}
            />}

            {(state.farmPanel === 'create' || (state.farmPanel === 'edit' && selectedFarm.farm)) && <CreateFarmPanel 
                farm={state.farmPanel === 'edit' ? selectedFarm.farm : null}
                backHandler={() => state.setFarmPanel('select')}
                createFarmHandler={farm => { state.setFarm(farm) }}
            />}
        </Container>
    )
}

const TopBarContainer = styled.div`
    height: 2rem;
    position: fixed;
    top: ${p => p.theme.padding};
    left: ${p => p.theme.sideMargins};

    display: grid;
    grid-auto-flow: column;
    grid-gap: ${p => p.theme.gridGap};
`

const TopBarMenu = styled.div<{active: boolean}>`
    position: absolute;
    top: calc(${p => p.theme.minSize} + ${p => p.theme.gridGap});
    left: 0;

    background-color: ${p => p.theme.colors.light};
    border-radius: ${p => p.theme.borderRadius};

    transition: .3s;
    transform: translateY(${p => p.active ? 0 : '-.5rem'});
    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};
    
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    padding: ${p => p.theme.padding};
`

const TopBarButtonContainer = styled.div`
    position: relative;
    min-width: ${p => p.theme.minSize};
`

type TMenu = 'menu' | 'farm' | 'layer'

interface ITopBarState {
    menu?: TMenu,
    setMenu(v: TMenu): void,
    save(): void
}

function TopBar({ farm, farmHandler, editFarmHandler }) {
    const router = useRouter()
    const notifications = useNotifications()
    const farmRole = useGetFarmRole()
    const viewport = useViewport()
    const auth = useAuth()
    const [state, set] = useState<ITopBarState>({
        setMenu(v) {
            if (this.menu === v) this.menu = null
            else this.menu = v

            this.save()
        },
        save() { set({ ...this })}
    })

    function alertComingSoon(): void {
        notifications.notify('Coming Soon')
    }

    function menuButtonHandler(type: 'user' | 'settings' | 'logout'): void {
        if (type === 'user') router.setPath(Paths.users, true)
        // if (type === 'settings') router.setPath(Paths.settings)
        if (type === 'logout') auth.logOut()
        state.setMenu('menu')
    }

    function farmButtonHandler(type: 'farms' | 'edit' | 'bounds'): void {
        if (type === 'farms') farmHandler()
        if (type === 'edit') editFarmHandler()
        if (type === 'bounds') alertComingSoon()
        state.setMenu('farm')
    }

    function layersButtonHandler(type: 'water' | 'soil' | 'ndvi'): void {
        alertComingSoon()
        state.setMenu('layer')
    }

    function unity3dClickHandler(): void {
        viewport.setContent(<Embed url={farm.unity3dUrl}/>)
        viewport.setTitle(`${farm.name}'s 3D Model`)
        viewport.setActive(true)
    }

    return (
        <TopBarContainer>
            <TopBarButtonContainer>
                <Button light onClick={() => state.setMenu('menu')} icon={Icons.menu} />
                <TopBarMenu active={state.menu === 'menu'}>
                    {farmRole != 'viewer' && <Button left light onClick={() => menuButtonHandler('user')} icon={Icons.profile}>Users</Button>}
                    {/* <Button left light onClick={() => menuButtonHandler('settings')} icon={Icons.settings}>Settings</Button> */}
                    <Button left light onClick={() => menuButtonHandler('logout')} icon={Icons.logout}>Logout</Button>
                </TopBarMenu>
            </TopBarButtonContainer>
            {farm && 
                <TopBarButtonContainer>
                    <Button light onClick={() => state.setMenu('farm')} icon={Icons.logo}>{farm.name}</Button>
                    <TopBarMenu active={state.menu === 'farm'}>
                        <Button left light onClick={() => farmButtonHandler('farms')} icon={Icons.farm}>Farms</Button>
                        {farmRole === 'admin' && <Button left light onClick={() => farmButtonHandler('edit')} icon={Icons.edit}>Edit Farm Details</Button>}
                        {farmRole === 'admin' && <Button left light onClick={() => farmButtonHandler('bounds')} icon={Icons.bounds}>Edit Farm Boundary</Button>}
                    </TopBarMenu>
                </TopBarButtonContainer>
            }
            <TopBarButtonContainer>
                <Button light onClick={() => state.setMenu('layer')} icon={Icons.layers} />
                <TopBarMenu active={state.menu === 'layer'}>
                    <Button left light onClick={() => layersButtonHandler('water')} icon={Icons.drop}>Waterways</Button>
                    <Button left light onClick={() => layersButtonHandler('soil')} icon={Icons.plant}>Soil</Button>
                    <Button left light onClick={() => layersButtonHandler('ndvi')} icon={Icons.leaf}>NDVI</Button>
                </TopBarMenu>
            </TopBarButtonContainer>
            {farm?.unity3dUrl && <Button left light onClick={() => unity3dClickHandler()} icon={Icons.threeD}>3D Model</Button>}
        </TopBarContainer>
    )
}

const FarmsContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, .8);
`

const FarmPanelContainer = styled.div<{show?: boolean}>`
    position: absolute;
    width: 20rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: ${p => p.show ? 1 : 0};
    transition: .5s;
    transition-timing-function: ease;
    transform: translate(-50%, ${p => p.show ? '-50%' : '-40%'});

    border-radius: ${p => p.theme.borderRadius};
    overflow: hidden;
`

const FarmsTitle = styled.div<{second?: boolean}>`
    background-color: ${p => p.theme.colors.alternative};
    padding: ${p => p.theme.padding};
    display: grid;
    grid-template-columns: ${p => p.theme.minSize} auto;
    ${p => p.second && css`
        grid-template-columns: ${p => p.theme.minSize} auto min-content;
    `}
`

const FarmsWhite = styled.div`
    background-color: ${p => p.theme.colors.light};
    padding: ${p => p.theme.padding};
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    /* grid-template-rows: auto ${p => p.theme.minSize}; */
`

const FarmsListContainer = styled.div`
    overflow: auto;
    max-height: 15rem;
`

const FarmList = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

function FarmPanel({ createFarmHandler, farms, farmClickHandler }) {
    const awake = useAwakeBool()
    const auth = useAuth()

    function getRoleIcon(farm: IFarm): string {
        let role: TRole = 'viewer'
        if (farm?.roles && auth.user?.localId in farm.roles) role = farm.roles[auth.user.localId]

        if (role === 'viewer') return Icons.eye
        if (role === 'admin') return Icons.key
        if (role === 'manager') return Icons.manager
    }

    return (
      <FarmsContainer>
        <FarmPanelContainer show={awake}>
          <FarmsTitle>
            <SVG image={Icons.logo} contain />
            <div />
            <Text bold middle>
              Farms
            </Text>
          </FarmsTitle>
          <FarmsWhite>
            <FarmsListContainer>
              <FarmList>
                {farms &&
                  farms.map((f) => (
                    <Button
                      key={f.id}
                      onClick={() => farmClickHandler(f)}
                      icon={getRoleIcon(f)}
                      left
                      light
                    >
                      {f.name}
                    </Button>
                  ))}
              </FarmList>
            </FarmsListContainer>
            <Button left onClick={() => createFarmHandler(0)} icon={Icons.plus}>
              Create a Farm
            </Button>
          </FarmsWhite>
        </FarmPanelContainer>
      </FarmsContainer>
    );
}


interface ICreateFarmPanel {
    name: string,
    setName(v: string),
    description: string,
    setDescription(v: string): void,
    unity3dUrl?: string,
    isPublic: boolean,
    setIsPublic(v: boolean): void,
    pins: Dictionary<IPin>,
    setPins(pins: Dictionary<IPin>): void,
    save(): void
}

function CreateFarmPanel({ backHandler, createFarmHandler, farm}) {
    const awake = useAwakeBool()
    const store = useStore()
    const auth = useAuth()
    const popup = usePopup()
    const [state, set] = useState<ICreateFarmPanel>({
        name: farm?.name || '',
        setName(v) {
            this.name = v
            this.save()
        },
        description: farm?.description || '',
        setDescription(v) {
            this.description = v
            this.save()
        },
        unity3dUrl: farm?.unity3dUrl || '',
        isPublic: farm?.public ? farm.public : false,
        setIsPublic(v) {
            this.isPublic = v
            this.save()
        },
        pins: farm?.pins ? farm.pins : {},
        setPins(pins) {
            this.pins = pins
            this.save()
        },
        save() { set({ ...this })}
    })

    function getFarm(): IFarm {
        if (farm) return { ...farm, name: state.name, description: state.description, public: state.isPublic, unity3dUrl: state.unity3dUrl }
        else {
            const roles = {}
            roles[auth.user.localId] = 'admin' // Set creator as admin
            logEvent('Create Farm')

            return {
                id: key(),
                name: state.name,
                description: state.description,
                unity3dUrl: state.unity3dUrl,
                public: state.isPublic,
                roles: roles,
                pins: state.pins
            }
        }
    }

    function saveFarm(): void {
        try {
            const saveFarm = getFarm()
            // Dispatch new farm to store/firebase.
            store.dispatch({ type: 'set-farm', payload: saveFarm })
            createFarmHandler(saveFarm)
        } catch {}
    }

    function deleteFarm(): void {
        // Ask for confirmation.
        popup.askForConfirmation(`Delete farm: ${farm.name}?`, () => {
            // Delete from store/firebase.
            store.dispatch({ type: 'delete-farm', payload: farm})
            createFarmHandler(null)
            backHandler()
        }, () => {})
    }

    // Set pins from KMZ/KML file.
    async function fileHandler(file): Promise<void> { 
        const pinsFromKMZ = await getPinsFromKMZ(file)

        state.setPins(pinsFromKMZ)
    }

    return (
      <FarmsContainer>
        <FarmPanelContainer show={awake}>
          <FarmsTitle second>
            <Button onClick={() => backHandler()} icon={Icons.back} />
            <div />
            <Text oneline bold middle>
              Create Farm
            </Text>
          </FarmsTitle>
          <FarmsWhite>
            <InputField
              value={state.name}
              onChange={(v) => state.setName(v)}
              placeholder="Farm Name"
            />
            <InputField
              value={state.description}
              onChange={(v) => state.setDescription(v)}
              placeholder="Farm Description"
            />
            <InputField
              value={state.unity3dUrl}
              onChange={(v) =>
                set((prev) => {
                  return { ...prev, unity3dUrl: v };
                })
              }
              placeholder="3D Model URL"
            />
            <div />
            <Checkbox
              value={state.isPublic}
              onChange={(v) => state.setIsPublic(v)}
              text="Public"
            />
            {!farm && (
              <Text bold dark>
                Upload KML/KMZ file
              </Text>
            )}
            {!farm && <FileSelector fileHandler={(f) => fileHandler(f)} />}
            <div />
            {/* {farm && <Button onClick={() => deleteFarm()} alternative reverse left icon={Icons.delete}>Delete</Button>} */}
            <Button onClick={() => saveFarm()} reverse left icon={Icons.check}>
              Save
            </Button>
          </FarmsWhite>
        </FarmPanelContainer>
      </FarmsContainer>
    );
}