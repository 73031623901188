import React from 'react'
import styled, { css } from 'styled-components'

import { Text, SVG } from './UI'
import infoIcon from '../graphics/icons/info.svg'

import { useNotifications } from '../hooks'

const Container = styled.div<{active: Boolean}>`
    position: fixed;
    background-color: ${p => p.theme.colors.alternative};
    top: ${p => p.theme.padding};
    right: ${p => p.theme.sideMargins};

    height: ${p => p.theme.minSize};

    display: grid;
    grid-template-columns: auto ${p => p.theme.minSize};
    grid-gap: ${p => p.theme.gridGap};
    min-width: 25rem;
    border-radius: ${p => p.theme.borderRadius};
    padding: 0 ${p => p.theme.padding};
    box-sizing: border-box;

    pointer-events: none;
    opacity: ${p => p.active ? '1' : '0'};
    ${p => !p.active && css` transform: translateY(1rem); `}

    transition: .3s;
`

export default function Notifications(props) {
    const notifications = useNotifications()

    return (
        <Container active={notifications.active}>
            <Text bold middle>{notifications.text}</Text>
            <SVG style={{height: '60%', width: '60%', margin: 'auto'}} image={infoIcon}/>
        </Container>
    )
}
