import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Text, InputField, Button } from '../components/UI'

import { usePopup } from '../hooks'

const Container = styled.div<{active?: boolean}>`
    display: ${p => p.active ? 'block' : 'none'};
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;

    z-index: 5;
`

const Background = styled.div`
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
`

const PanelContainer = styled.div`
    position: absolute;
    width: 20rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const Panel = styled.div<{show?: boolean}>`
    background-color: ${p => p.theme.colors.light};
    border-radius: ${p => p.theme.borderRadius};
    overflow: hidden;
    display: grid;
    grid-gap: ${p => p.theme.gridGap};

    opacity: ${p => p.show ? 1 : 0};
    transition: .5s;
    transition-timing-function: ease;
    transform: translateY(${p => p.show ? 0 : '1rem'});
`

const Row = styled.div<{double?: boolean}>`
    display: grid;
    grid-template-columns: ${p => p.double ? '1fr 1fr' : '1fr'};
    grid-gap: ${p => p.theme.gridGap};
    height: ${p => p.theme.minSize};
    padding: ${p => p.theme.padding};
`

const Title = styled.div`
    padding: ${p => p.theme.padding};
    background-color: ${p => p.theme.colors.alternative};
`

export default function Popup() {
    const popup = usePopup()
    const [show, setShow] = useState(popup.active)

    useEffect(() => {
        if (popup.active) {
            setShow(false)
            setTimeout(() => {
                setShow(true)
            }, 10)
        }
    }, [popup.active])

    return (
        <Container active={popup.active}>
            <Background />
            <PanelContainer>
            <Panel show={show}>
                <Title> <Text center middle bold>{popup.title}</Text> </Title>
                {popup.type === 'inputfield' && 
                    <Row>
                        <InputField 
                            onKeyDown={e => { if (e.key === 'Enter') { popup.confirmAction() } }}
                            value={popup.inputfield}
                            placeholder={popup.placeholder}
                            onChange={v => popup.setInputField(v)}
                        />
                    </Row>
                }
                <Row double>
                    <Button onClick={() => popup.cancelAction()} alternative>{popup.cancelText}</Button>
                    <Button onClick={() => popup.confirmAction()}>{popup.confirmText}</Button>
                </Row>
            </Panel>
            </PanelContainer>
        </Container>
    )
}