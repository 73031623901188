import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { FileSelector, Text, Button } from '../components/UI'

const JSZip = require('jszip')
const tj = require('@tmcw/togeojson')
const DOMParser = require('xmldom').DOMParser
const reader = new FileReader()

const Container = styled.div`
    height: 100vh;
    background-color: black;
    position: relative;
`

const Panel = styled.div`
    background-color: ${p => p.theme.colors.light};
    border-radius: ${p => p.theme.borderRadius};

    position: absolute;
    top: 50%;
    left: 50%;
    padding: ${p => p.theme.padding};

    transform: translate(-50%, -50%);
`

function parseKml(text) {
    const kml = new DOMParser().parseFromString(text)
    const converted = tj.kml(kml)
    console.log(converted)
}

function readFile(file) {
    // return
    reader.onload = function(event) {
        parseKml(event.target.result)
    }

    reader.readAsText(file)
}

export default function UIPlayground() {
    const [state, set] = useState<any>({})

    useEffect(() => {
        if (!state.file) return

        if (state.file.name.toLowerCase().includes('.kmz')) JSZip.loadAsync(state.file).then(function(zip) { 
            zip.forEach(function (relativePath, file) {
                file.async('string').then(value => parseKml(value))
            })
        })
        else readFile(state.file)

    }, [state])

    return (
        <Container>
            <Panel>
                <FileSelector fileHandler={f => set(prev => { return {...prev, file: f}})}/>
            </Panel>
        </Container>
    )
}