import logoIcon from '../../brand/logo.svg'

import backIcon from './back.svg'
import checkIcon from './check.svg'
import deleteIcon from './delete.svg'
import menuIcon from './menu.svg'
import layersIcon from './layers.svg'
import plusIcon from './add.svg'
import logoutIcon from './logout.svg'
import profileIcon from './profile.svg'
import settingsIcon from './settings.svg'
import farmIcon from './farm.svg'
import editIcon from './edit.svg'
import boundsIcon from './bounds.svg'
import dropIcon from './drop.svg'
import plantIcon from './plant.svg'
import leafIcon from './leaf.svg'
import addIcon from './add.svg'
import taskIcon from './task.svg'
import noteIcon from './notes.svg'
import sensorIcon from './sensor.svg'
import lockIcon from './lock.svg'
import enterIcon from './enter.svg'
import eyeIcon from './eye.svg'
import keyIcon from './key.svg'
import managerIcon from './manager.svg'
import image360Icon from './360-image.svg'
import webIcon from './web.svg'
import threeDIcon from './3d.svg'

const Icons = {
    back: backIcon,
    check: checkIcon,
    delete: deleteIcon,
    menu: menuIcon,
    logo: logoIcon,
    layers: layersIcon,
    plus: plusIcon,
    logout: logoutIcon,
    profile: profileIcon,
    settings: settingsIcon,
    farm: farmIcon,
    edit: editIcon,
    bounds: boundsIcon,
    drop: dropIcon,
    plant: plantIcon,
    leaf: leafIcon,
    add: addIcon,
    task: taskIcon,
    note: noteIcon,
    sensor: sensorIcon,
    lock: lockIcon,
    enter: enterIcon,
    eye: eyeIcon,
    key: keyIcon,
    manager: managerIcon,
    image360: image360Icon,
    web: webIcon,
    threeD: threeDIcon,
}

export default Icons