import React from 'react'
import styled from 'styled-components'

import { useStore } from '../../hooks'
import { IFarm, TRole } from '../../store'
import { IUser } from '../../context/AuthContext'
import { TView } from '../Users'

import { Text, SVG, Button } from '../../components/UI'
import Icons from '../../graphics/icons'

const Container = styled.div`
    position: absolute;
    top: calc(${p => p.theme.minSize} + ${p => p.theme.gridGap} + ${p => p.theme.padding});
    bottom: ${p => p.theme.padding};
    left: 20vw;
    right: 20vw;

    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-rows: ${p => p.theme.minSize} auto;
`

const TopBar = styled.div`
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;

    *:nth-child(-n+2) {
        border-right: solid ${p => p.theme.lineSize} ${p => p.theme.colors.dark};
    }

    * {
        padding: 0 ${p => p.theme.padding};
    }
`

const GridPanel = styled.div`
    overflow: auto;
`

const GridContainer = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

interface IProps {
    farm: IFarm,
    searchValue: string,
    view: TView,
    selectUser(u: IUser): void,
    isAdmin: boolean
}

export default function List(props: IProps) {
    const store = useStore()

    function getUsers() {
        try {
            return Object.values(store.state.users)
                // Filter by search bar.
                .filter(u => {
                    if (props.searchValue === '') return true

                    const s = props.searchValue.toLowerCase()
                    return u?.email.toLowerCase().includes(s) || u?.firstName?.includes(s) || u?.lastName?.includes(s)
                })
                // Filter by view (users or invite)
                .filter(u => {
                    if (u.localId in props?.farm?.roles) return props.view === 'users'
                    else return props.view === 'invite'
                })
                // Generate React user Tags.
                .map(u => {
                    const permissionType: TRole = props?.farm?.roles[u.localId]
                    return <Tag selectUser={u => { 
                        if (props.isAdmin) props.selectUser(u)
                    }} key={u.localId} user={u} permissionType={permissionType} />
                })
        } catch { return [] }
    }

    return (
        <Container>
            <TopBar>
                <Text dark bold middle>Username</Text>
                <Text dark bold middle>Email</Text>
                <Text dark bold middle>Permissions</Text>
            </TopBar>
            <GridPanel>
                <GridContainer>
                    { getUsers() }
                </GridContainer>
            </GridPanel>
        </Container>
    )
}

const TagContainer = styled.div`
    height: ${p => p.theme.minSize};
    background-color: ${p => p.theme.colors.darkLight};
    border-radius: ${p => p.theme.borderRadius};

    display: grid;
    padding: 0 ${p => p.theme.padding};
    grid-template-columns: 2fr 2fr 1fr;

    cursor: pointer;
    :hover {
        filter: brightness(95%);
    }
`

function Tag({ user, permissionType, selectUser }: {user: IUser, permissionType?: TRole, selectUser(u: IUser): void}) {
    return (
        <TagContainer onClick={() => selectUser(user)}>
            <Text dark middle>{`${user?.firstName} ${user?.lastName}`}</Text>
            <Text dark middle>{user?.email}</Text>
            <Permission permissionType={permissionType}/>
        </TagContainer>
    )
}

const PermissionContainer = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-columns: ${p => p.theme.minSize} auto;

    *:nth-child(1) {
        display: flex;
        margin: auto;
        height: 50%;
        width: 50%;
    }
`

function Permission({ permissionType }: { permissionType: TRole }) {
    const data : { [key in TRole]: {name: string, icon: string}} = {
        'admin': {name: 'Admin', icon: Icons.key},
        'viewer': {name: 'Viewer', icon: Icons.eye},
        'manager': {name: 'Manager', icon: Icons.manager}
    }

    if (permissionType) return (
        <PermissionContainer>
            <SVG dark contain image={data[permissionType].icon}/> 
            <Text middle dark>{data[permissionType].name}</Text> 
        </PermissionContainer>
    )

    else return (
        <Button onClick={() => {}}>Invite to farm</Button>
    )
}