import React, { useState } from 'react'

import { Dictionary } from '../store'

const Events = React.createContext({})

type TEvent = 'map-click'

export interface IEvents {
    events: Dictionary<any[]>,
    dispatch(key: TEvent, data: any): void,
    on(key: TEvent, listener: any): any,
    save(): void
}

export function EventsContext(props: any) {
    const [state, set] = useState<IEvents>({
        events: {},
        dispatch(key, data) { if (this.events[key]) this.events[key].forEach(l => l(data)) },
        on(key, listener) {
            if (!this.events[key]) this.events[key] = [] 
            this.events[key].push(listener)
            this.save()
            return () => this.events[key].pop(listener)
        },
        save() { set({ ...this })}
    })

    return (
        <Events.Provider value={state}>
            { props.children }
        </Events.Provider>
    )
}

export default Events