import React, { useState } from 'react'
import styled from 'styled-components'

import Logo from '../components/Logo'

import { InputField, Button, Text } from '../components/UI'
import { useAwakeBool, useAuth, usePopup, useNotifications } from '../hooks'
import Loading from '../components/Loading'
import { sendResetPasswordEmail } from '../firebase/auth'

import { Paths } from '../context/RouterContext'
import Icons from '../graphics/icons'

const BackgroundGif = require('../graphics/images/loginBackground.PNG')

const Container = styled.div`
    position: relative;
    height: 100vh;

    background-color: ${p => p.theme.colors.dark};

    background:
        linear-gradient(
            rgba(0, 0, 0, .8),
            rgba(0, 0, 0, .8)
        ),
        url(${BackgroundGif});

    background-size: 100%;
`

const Panel = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;

    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

const LoadingContainer = styled.div`
    height: 10rem;
`

type TView = 'login' | 'register'

interface ILogin {
    view: TView,
    loading: Boolean,
    setLoading(v: Boolean): void,
    setView(view: TView): void,
    save(): void
}

export default function Login() {
    const [state, set] = useState<ILogin>({
        view: 'login',
        loading: false,
        setLoading(v) {
            this.loading = v
            this.save()
        },
        setView(view) {
            this.view = view
            this.save()
        },
        save() { set({ ...this })}
    })

    function getContent() {
        if (state.view === 'login') return <LoginPanel 
            loading={state.loading}
            setLoading={v => state.setLoading(v)}
            newUserHandler={() => state.setView('register')}
        />
        if (state.view === 'register') return <RegisterPanel 
            loading={state.loading}
            setLoading={v => state.setLoading(v)}
            backHandler={() => state.setView('login')}
        />
    }

    return (
        <Container>
            <Panel>
                {state.loading && <LoadingContainer><Loading /></LoadingContainer>}
                {getContent()}
            </Panel>
        </Container>
    )
}

const ShowPanelContainer = styled.div<{ show: Boolean, doDisplay: Boolean }>`
    display: ${p => p.doDisplay ? 'grid' : 'none'};
    grid-gap: ${p => p.theme.gridGap};
    opacity: ${p => p.show ? 1 : 0};
    transition: .5s;
    transition-timing-function: ease;
    transform: translateY(${p => p.show ? 0 : '2rem'});
`

const LoginPanelWhite = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    padding: ${p => p.theme.padding};

    background-color: ${p => p.theme.colors.light};
    border-radius: ${p => p.theme.borderRadius};
`

const LogoHolder = styled.div`
    height: 7rem;
`

const NewUserContainer = styled.div`
    padding: ${p => p.theme.padding};
`

interface ILoginPanel {
    email: string,
    password: string,
    setEmail(v: string): void,
    setPassword(v: string): void,
    setFeedback(v: string): void,
    feedback?: string,
    save(): void,
}

function LoginPanel({ newUserHandler, setLoading, loading }) {
    const auth = useAuth()
    const awakeBool = useAwakeBool()
    const popup = usePopup()
    const notifications = useNotifications()
    const [state, set] = useState<ILoginPanel>({
        email: '',
        password: '',
        setEmail(v) {
            this.email = v
            this.save()
        },
        setPassword(v) {
            this.password = v
            this.save()
        },
        setFeedback(v) {
            this.feedback = v
            setLoading(false)
            this.save()
        },
        save() { set({ ...this })} 
    })

    async function loginHandler(): Promise<void> {
        try {
            setLoading(true)
            await auth.logIn(state.email, state.password)
        } catch(error) { state.setFeedback(error.message) }
    }

    function forgotPasswordHandler(): void {
        popup.askForText('Enter the email address you used when creating the account. A message will be sent to reset your password.',
        async email => {
            try { 
                await sendResetPasswordEmail(email)
                notifications.notify(`Reset password email sent to: ${email}`)
            } catch(error) { notifications.notify(error) }
        },
        'example@example.com', () => {}, 'Send',  'Cancel')
    }

    return (
        <ShowPanelContainer show={awakeBool} doDisplay={!loading}>
            <LoginPanelWhite>
                <div />
                <LogoHolder> <Logo/> </LogoHolder>
                <InputField
                    onKeyDown={e => { if (e.key === 'Enter') { loginHandler() } }}
                    value={state.email}
                    onChange={v => state.setEmail(v)}
                    placeholder={'Email'}
                    type='email'
                />
                <InputField
                    onKeyDown={e => { if (e.key === 'Enter') { loginHandler() } }}
                    value={state.password}
                    onChange={v => state.setPassword(v)}
                    placeholder={'Password'}
                    type='password'
                />
                {state.feedback && <Text style={{color: 'red'}} bold>{state.feedback}</Text>}
                <Button icon={Icons.enter} reverse left onClick={() => loginHandler()}>Log In</Button>
                <Text onClick={() => forgotPasswordHandler()} primary bold center>I forgot my password</Text>
                <div />
            </LoginPanelWhite>
            <NewUserContainer>
                <Button onClick={() => newUserHandler()} alternative>New User</Button>
            </NewUserContainer>
        </ShowPanelContainer>
    )
}

const RegisterContainer = styled.div`
    display: grid;
    /* grid-gap: ${p => p.theme.gridGap}; */
    overflow: hidden;
    border-radius: ${p => p.theme.borderRadius};
`

const RegisterTop = styled.div`
    background-color: ${p => p.theme.colors.alternative};
    padding: ${p => p.theme.padding};
    display: grid;
    grid-template-columns: ${p => p.theme.minSize} auto min-content;
`

const RegisterWhite = styled.div`
    background-color: ${p => p.theme.colors.light};
    padding: ${p => p.theme.padding};
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

interface IRegister {
    firstName: string,
    lastName: string,
    country: string,
    email: string,
    password: string,
    confirmPassword: string,

    setField(field: string, value: string): void,

    feedback?: string,
    setFeedback(value: string): void,

    save(): void
}

function RegisterPanel({ backHandler, setLoading, loading }) {
    const awakeBool = useAwakeBool()
    const auth = useAuth()
    const [state, set] = useState<IRegister>({
        firstName: '',
        lastName: '',
        country: '',
        email: '',
        password: '',
        confirmPassword: '',
        setField(field, value) {
            this[field] = value
            this.save()
        },
        setFeedback(value) {
            this.feedback = value
            setLoading(false)
        },
        save() { set({ ...this })}
    })

    async function registerHandler(): Promise<void> {
        if (!state.email || !state.password || !state.confirmPassword) {
            state.setFeedback(`Can't be empty fields.`)
            return
        }

        if (state.password != state.confirmPassword) {
            state.setFeedback(`passwords don't match.`)
            return
        }

        setLoading(true)

        try {
            await auth.register({
                firstName: state.firstName,
                lastName: state.lastName,
                country: state.country,
                email: state.email,
            }, state.password)
        } catch(error) { state.setFeedback(error.message) }
    }

    return (
        <ShowPanelContainer show={awakeBool} doDisplay={!loading}>
            <RegisterContainer>
                <RegisterTop>
                    <Button onClick={() => backHandler()} icon={Icons.back}/>
                    <div />
                    <Text bold oneline middle>New user</Text>
                </RegisterTop>
                <RegisterWhite>
                    <InputField 
                        onKeyDown={e => { if (e.key === 'Enter') { registerHandler() } }}
                        value={state.firstName}
                        onChange={v => state.setField('firstName', v)}
                        placeholder='First Name'
                    />
                    <InputField 
                        onKeyDown={e => { if (e.key === 'Enter') { registerHandler() } }}
                        value={state.lastName}
                        onChange={v => state.setField('lastName', v)}
                        placeholder='Last Name'
                    />
                    {/* <InputField 
                        onKeyDown={e => { if (e.key === 'Enter') { registerHandler() } }}
                        value={state.country}
                        onChange={v => state.setField('country', v)}
                        placeholder='Country'
                    /> */}
                    <InputField 
                        onKeyDown={e => { if (e.key === 'Enter') { registerHandler() } }}
                        value={state.email}
                        type='email'
                        onChange={v => state.setField('email', v)}
                        placeholder='Email'
                    />
                    <InputField 
                        onKeyDown={e => { if (e.key === 'Enter') { registerHandler() } }}
                        value={state.password}
                        type='password'
                        onChange={v => state.setField('password', v)}
                        placeholder='Password'
                    />
                    <InputField 
                        onKeyDown={e => { if (e.key === 'Enter') { registerHandler() } }}
                        value={state.confirmPassword}
                        type='password'
                        onChange={v => state.setField('confirmPassword', v)}
                        placeholder='Confirm Password'
                    />
                    {state.feedback && <Text style={{color: 'red'}} bold>{state.feedback}</Text>}
                    <Button left reverse onClick={() => registerHandler()} icon={Icons.check}>Create</Button>
                </RegisterWhite>
            </RegisterContainer>
        </ShowPanelContainer>
    )
}