import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { useUrl } from '../hooks'

const FileSelectorContainer = styled.input`
    background-color: ${p => p.theme.colors.primary};
    padding: .4rem;
    color: ${p => p.theme.colors.light};
    font-weight: bold;
    border-radius: ${p => p.theme.borderRadius};
    cursor: pointer;
    :hover { filter: brightness(120%); }
`

interface IFileSelectorProps {
    fileHandler(f: any): void
}

export function FileSelector(props: IFileSelectorProps) {
    function handleChange(e) {
        if (e.target.files[0]) props.fileHandler(e.target.files[0])
    }

    return (
        <FileSelectorContainer onChange={e => handleChange(e)} type='file' />
    )
}

interface ICheckboxProps {
    text?: string,
    icon?: string,
    value: boolean,
    onChange(v: boolean): void,
}

const CheckBoxBox = styled.div<{selected?: boolean}>`
    width: 1.5rem;
    border-radius: 50%;
    box-sizing: border-box;

    background-color: ${p => p.theme.colors.primary};

    * {
        transition: .5s;
        transform: scale(${p => p.selected ? 1 : 0});        
    }

    display: flex;
    align-items: center;
`

const CheckBoxMark = styled.div`
    width: 1rem;
    height: 1rem;
    margin: auto;

    border-radius: 50%;
    box-sizing: border-box;

    background-color: ${p => p.theme.colors.dark};
`

const CheckboxContainer = styled.div`
    display: grid;
    height: 1.5rem;
    grid-gap: ${p => p.theme.gridGap};
    grid-auto-flow: column;
    width: min-content;
    cursor: pointer;
    :hover { * { filter: brightness(120%); } }
`

export function Checkbox(props: ICheckboxProps) {
    const [state, set] = useState(props.value)

    useEffect(() => props.onChange(state), [state])

    return (
        <CheckboxContainer onClick={() => set(!state)}>
            <CheckBoxBox selected={state}><CheckBoxMark /></ CheckBoxBox>
            {props.icon && <SVG primary style={{width: '1.5rem'}} image={props.icon}/>}
            {props.text && <Text oneline middle primary bold>{props.text}</Text>}
        </ CheckboxContainer>
    )
}

const ImageContainer = styled.div<{image?: string, placeholder?: string, contain?: boolean, fit?: boolean}>`
    box-sizing: border-box;
    background-image: url(${p => p.image}), url(${p => p.placeholder});
    background-size: ${p => p.contain ? 'contain' : p.fit ? '100% 100%' : 'cover'};
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
`

interface IImageProps {
    url?: string,
    image?: string,
    placeholder?: string,
    contain?: boolean,
    fit?: boolean,
    children?: any,
    style?: any
}

export function Image(props: IImageProps) {
    const url = useUrl(props.url)
    return (
        <ImageContainer 
            style={props.style}
            image={url ? url : props.image}
            contain={props.contain}
            fit={props.fit}
        > {props.children} </ ImageContainer>
    )
}

export const Text = styled.div<{big?: boolean, subTitle?: boolean, bold?: boolean, dark?: boolean, alternative?: boolean, medium?: boolean, primary?: boolean, oneline?: boolean, center?: boolean, middle?: boolean, bottom?: boolean}>`
    box-sizing: border-box;
    font-size: ${p => p.big ? p.theme.fontSize.title : p.subTitle ? p.theme.fontSize.subTitle : p.theme.fontSize.text};
    font-weight: ${p => p.bold ? 'bold' : 'normal'};
    color: ${p => p.dark ? p.theme.colors.dark : p.primary ? p.theme.colors.primary : p.medium? p.theme.colors.medium : p.alternative ? p.theme.colors.alternative : p.theme.colors.light};
    height: 100%;
    white-space: ${p => p.oneline ? 'nowrap' : 'pre-line'};
    
    ${p => p.center && css`
        text-align: center;
    `}

    ${p => p.onClick && css`
        cursor: pointer;
        :hover {
            filter: brightness(120%);
        }
    `}

    ${p => p.middle && css`
        display: flex;
        align-items: center;

        ${p.center && css`
            justify-content: center;
        `}
    `}

    ${p => p.bottom && css`
        display: flex;
        align-items: flex-end;

        ${p.center && css`
            justify-content: center;
        `}
    `}
`

export const SVG = styled.div<{ image: string, contain?: boolean, dark?: boolean, primary?: boolean, alternative?: boolean, medium?: boolean}> `
    mask-image: url(${p => p.image});
    mask-size: ${p => p.contain ? 'contain' : 'cover'};
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: ${p => p.dark ? p.theme.colors.dark : p.primary ? p.theme.colors.primary : p.medium ? p.theme.colors.medium : p.alternative ? p.theme.colors.alternative : p.theme.colors.light};
    height: 100%;
`

const InputFieldContainer = styled.input<{ center?: boolean }>`
    height: 100%;
    border: .1rem solid ${p => p.theme.colors.medium};

    color: ${p => p.theme.colors.medium};
    font-size: 1rem;

    height: 2.5rem;
    background-color: rgba(0,0,0,0);
    padding: 0 1rem;
    ${p => p.center && css`
        text-align: center;
    `}

    ::placeholder {
        color: ${p => p.theme.colors.medium};
        opacity: 1;
    }

    :hover {
        filter: brightness(120%);
    }

    border-radius: ${p => p.theme.borderRadius};
`

type TInputFieldProps = {
    autoSelect?: boolean,
    onKeyDown?(e): void,
    center?: boolean,
    placeholder?: string,
    value: string,
    onChange(v: string): void,
    type?: string,
    style?: any
}

export function InputField(props: TInputFieldProps) {
    const inputRef = useRef()

    useEffect(() => {
        if (props.autoSelect && inputRef.current) (inputRef.current as any).select()
    }, [inputRef.current])

    return (
        <InputFieldContainer 
            ref={inputRef}
            onKeyDown={props.onKeyDown}
            center={props.center}
            placeholder={props.placeholder}
            value={props.value ? props.value : ''}
            onChange={e => props.onChange(e.target.value)}
            type={props.type ? props.type : 'text'}
            style={props.style}/>
    )
}

const ButtonContainer = styled.div< {dark?: boolean, light?: boolean, center?: boolean, alternative?: boolean, icon?: boolean, reverse?: boolean} >`
    box-sizing: border-box;
    height: ${p => p.theme.minSize};
    background-color: ${p => p.dark ? p.theme.colors.dark : p.light ? p.theme.colors.light : p.alternative ? p.theme.colors.alternative : p.theme.colors.primary};
    cursor: pointer;
    :hover {
        filter: brightness(120%);

        ${Text} { 
            color: ${p => p.light ? p.theme.colors.primary : p.theme.colors.light}; 
        }
    }

    ${Text} {
        display: flex;
        align-items: center;
        ${p => p.center && css`justify-content: center;`}
    }

    border-radius: ${p => p.theme.borderRadius};

    ${p => p.icon && css`
        display: grid;

        grid-template-columns: ${p.reverse ? `auto ${p.theme.minSize}` : `${p.theme.minSize} auto`};

        ${p.light && css `
            :hover {
                ${SVG} {
                    background-color: ${p => p.theme.colors.primary}
                }
            }
        `}
    `}
`

type TButtonProps = {
    style?: any,
    onClick(): void,
    dark?: boolean,
    light?: boolean,
    alternative?: boolean,
    icon?: any,

    children?: any,
    left?: boolean,
    reverse?: boolean
}

export function Button(props: TButtonProps) {
    function getContent() {
        let list = []
        if (props.icon) list.push(<SVG style={{margin: 'auto', height: '45%', width: '45%'}} contain key='icon' medium={props.light} image={props.icon}/>)
        if (props.children) list.push(<Text key='text' style={{whiteSpace: 'nowrap', margin: '0 1rem'}} bold medium={props.light}> {props.children} </Text>)
        if (props.reverse) list = list.reverse()

        return list
    }

    return (
        <ButtonContainer icon={props.icon} reverse={props.reverse} center={!props.left} style={props.style} onClick={() => props.onClick()} alternative={props.alternative} dark={props.dark} light={props.light}>
            { getContent() }
        </ButtonContainer>
    )
}