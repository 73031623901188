import React from 'react'
import styled from 'styled-components'

import { usePinMenu, useEditPin } from '../hooks'

import { InfoBox, OverlayView } from '@react-google-maps/api'
import { IPin } from '../store'
import { SVG } from './UI'
import Icons from '../graphics/icons'

interface IMarkProps {
    position: any,
    pin: IPin
}

const Container = styled.div`
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
`

const MarkerContainer = styled.div<{selected: boolean}>`
    position: absolute;
    top: -115%;
    left: -50%;
    background-color: ${p => p.selected ? p.theme.colors.light : p.theme.colors.primary};
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50% 50% 50% 0;
    transform: rotate(-45deg);

    transition: .3s;
    cursor: pointer;
    :hover {
        transform: translateY(-.5rem) scale(1.1) rotate(-45deg);
    }
    
    * { 
        transform: rotate(45deg) scale(.6); 
    }
`

export default function Marker(props: IMarkProps) {
    const pinMenu = usePinMenu()
    const editPin = useEditPin()

    function getIcon() {
        if (props.pin.type === 'note') return Icons.note
        if (props.pin.type === 'sensor') return Icons.sensor
        if (props.pin.type === 'todo') return Icons.task
        if (props.pin.type === '360image') return Icons.image360
        if (props.pin.type === 'web') return Icons.web
        if (props.pin.type === '3d') return Icons.threeD
    }

    function clickHandler() { if (!editPin.pin) pinMenu.setSelected(props.pin) }

    const isSelected = pinMenu?.selected?.id === props.pin.id || editPin.pin?.id === props.pin.id

    return (
        <OverlayView 
            position={props.position}
            mapPaneName={OverlayView.FLOAT_PANE}
        >
            <Container>
                <MarkerContainer selected={isSelected} onClick={() => clickHandler()}>
                    <SVG primary={isSelected} contain image={getIcon()}/>
                </MarkerContainer>
            </Container>
        </OverlayView>
    )
}