import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import fetch from 'node-fetch'

import { IPin } from '../store'
import { Text, SVG, Button, Image } from './UI' 
import { useEditPin, usePinMenu, usePopup, useGetFarmRole, useViewport } from '../hooks'

import { openNewTab } from '../utility'

import Embed from '../components/Embed'

import Icons from '../graphics/icons'

const Container = styled.div`
    background-color: ${p => p.theme.colors.alternative};
    border-radius: ${p => p.theme.borderRadius};
    overflow: hidden;
    display: grid;

    cursor: pointer;
    transition: .3s;
    :hover { transform: translateX(-.5rem); }
`

const PictureContainer = styled.div`
    height: 12rem;
`

const InfoContainer = styled.div`
    padding: ${p => p.theme.padding};
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    transition: .3s;
`

const NameRow = styled.div`
    display: grid;
    grid-template-columns: 1rem auto;
    /* height: ${p => p.theme.minSize}; */
    grid-gap: ${p => p.theme.gridGap};
`

interface IProps {
    pin: IPin,
    selected: boolean,
    selectHandler(p: IPin): void
}

export default function PinPanel(props: IProps) {
    const ref = useRef()
    const editPin = useEditPin()
    const farmRole = useGetFarmRole()
    const popup = usePopup()
    const viewport = useViewport()
    const [sensorData, setSensorData] = useState('')

    function getIcon(): string {
        if (props.pin.type === 'note') return Icons.note
        if (props.pin.type === 'sensor') return Icons.sensor
        if (props.pin.type === 'todo') return Icons.task
        if (props.pin.type === '360image') return Icons.image360
        if (props.pin.type === 'web') return Icons.web
        if (props.pin.type === '3d') return Icons.threeD

        return ''
    }

    function editHandler(): void {
        editPin.setPin(props.pin, 'data')
    }

    function deleteHandler(): void {
        popup.askForConfirmation(`Delete ${props?.pin?.name ? props.pin.name : 'Pin'}?`,
        () => {
            editPin.setPin(props.pin, 'position')
            setTimeout(() => {
                editPin.deletePin()
                editPin.close()
            }, 10)
        }, () => {})
    }

    function getSensorData() {
        const list = [
            <Text key='list' bold>Sensor Data</Text>,
            <Text key='sensorApiUrl'>{sensorData}</Text>,
            props.pin?.sensorData?.sensorSupplierUrl && <Button key='viewSupplier' onClick={() => openNewTab(props.pin.sensorData.sensorSupplierUrl)}>View Supplier</Button>,
        ]

        return list
    }

    function isViewportType() { return props.pin?.type === '360image' || props.pin?.type === 'web' }

    function viewportClickHandler() {
        if (!props.pin?.viewportData?.url) return

        viewport.setContent(<Embed url={props.pin.viewportData.url} />)
        viewport.setTitle(props.pin.viewportData.title)
        viewport.setActive(true)
    }

    useEffect(() => {
        if (!props.selected) return

        setSensorData(props.pin?.sensorData?.sensorApiUrl ? 'Fetching sensor data...' : 'No sensor data.')
        // Fetch sensor data.

        if (props.pin?.sensorData?.sensorApiUrl) {
            fetch(`https://treeview-cors.herokuapp.com/${props.pin.sensorData.sensorApiUrl}`)
                .then(res => res.json())
                .then(json => {
                    try {
                        const snapshot = json[json.length - 1]
                        setSensorData(`${snapshot.metric} : ${snapshot.value} ${snapshot.uom}`)
                    } catch { setSensorData('No sensor data.')}
                })
        }

        try {
            setTimeout(() => {
                // @ts-ignore
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }, 100)
        } catch { }
    }, [props.selected])

    return (
        <Container ref={ref} onClick={() => props.selectHandler(props.pin)}>
            {(props.selected && props.pin.image) && <PictureContainer><Image url={props.pin.image}/></PictureContainer>}
            <InfoContainer>
                <NameRow>
                    <SVG image={getIcon()}/>
                    <Text bold middle oneline>{props.pin.name}</Text>
                </NameRow>
                    {props.selected && <Text>{props.pin.description}</Text> }
                    {(props.selected && props.pin.type === 'sensor') && getSensorData() }
                    {(props.selected && isViewportType()) && <Button onClick={() => viewportClickHandler()}>Open</Button>}
                    {(props.selected && farmRole != 'viewer') && <Button left onClick={() => editHandler()} icon={Icons.edit} light>Edit</ Button>}
                    {(props.selected && farmRole != 'viewer') && <Button left onClick={() => deleteHandler()} icon={Icons.delete}>Delete</ Button>}
            </InfoContainer>
        </Container>
    )
}