import React, { useState, useEffect } from 'react'

const Popup = React.createContext({})

type TPopup = 'inputfield' | 'confirm'

export interface IPopup {
    title: string,
    confirmAction(): void,
    confirmText: string,
    cancelAction(): void,
    cancelText: string,
    active: boolean,
    inputfield: string,
    setInputField(value: string): void,
    type: TPopup,
    placeholder?: string,
    askForText(title: string, onConfirm: (value) => void, placeholder: string, onCancel: () => void, confirmText: string, cancelText: string),
    askForConfirmation(title: string, onConfirm: () => void, onCancel: () => void, confirmText?: string, cancelText?: string),
    save(): void
}

export function PopupContext(props) {
    const [state, set] = useState<IPopup>({
        title: '',
        confirmAction: () => {},
        confirmText: 'Confirmar',
        cancelAction: () => {},
        cancelText: 'Cancelar',
        active: false,
        inputfield: '',
        type: 'inputfield',
        placeholder: '',
        setInputField(value) {
            this.inputfield = value
            this.save()
        },
        askForText: (title, onConfirm, placeholder, onCancel, confirmText='Confirm', cancelText='Cancel') => { 
            set(prev => { return {...prev,
                type: 'inputfield',
                title: title,
                placeholder: placeholder,
                confirmAction: () => {
                    set(prev => {
                        onConfirm(prev.inputfield)
                        return {...prev, active: false, inputfield: ''}
                    })
                },
                cancelAction: () => {
                    set(prev => {
                        if (onCancel) onCancel()
                        return {...prev, active: false, inputfield: ''}
                    })
                },
                confirmText: confirmText,
                cancelText: cancelText,
                active: true,
            }}
        )},
        askForConfirmation(title, onConfirm, onCancel, confirmText='Confirm', cancelText='Cancel') {
            set(prev => { return {...prev,
                type: 'confirm',
                title: title,
                confirmAction: () => {
                    set(prev => {
                        onConfirm()
                        return {...prev, active: false, inputfield: ''}
                    })
                },
                cancelAction: () => {
                    set(prev => {
                        if (onCancel) onCancel()
                        return {...prev, active: false, inputfield: ''}
                    })
                },
                confirmText: confirmText,
                cancelText: cancelText,
                active: true,
            }})
        },
        save() { set({ ...this })}
    })

    return <Popup.Provider value={state}>{ props.children }</Popup.Provider>
}

export default Popup