import React, { useState, useEffect } from 'react'

import { useAuth, useNotifications, useStore, useSelectedFarm } from '../hooks'

import { IPin, TPin, IFarm, IStore, ISensorData, IViewportData } from '../store'


const EditPin = React.createContext({})

type TView = 'position' | 'type' | 'data'

export interface IEditPinState {
    active: boolean,
    pin?: IPin,
    farm?: IFarm,
    setPin(pin: IPin, view: TView): void,
    editPin(pin: IPin): void,
    updateState(): void,
    updatePinLocationData(latitude: string, longitude: string): Boolean,
    setPinName(name: string): void,
    setPinDescription(description: string): void,
    setPinLatitude(lat: string): void,
    setPinLongitude(lng: string): void,
    setPinType(type: string): void,
    setPinEmail(email: string): void,
    setPinSensorData(data: ISensorData): void,
    setPinViewportData(data: IViewportData): void,
    storePin(): void,
    deletePin(): void,
    disposePin(): void,
    close(): void,
    view?: TView,
    firstView?: TView,
    setView(view: TView): void,
    save(): void
}

export function EditPinContext(props: any) {
    const notifications = useNotifications()
    const store = useStore()
    const selectedFarm = useSelectedFarm()
    const [state, set] = useState<IEditPinState>({
        active: false,
        setPin(pin, view) {
            this.pin = pin // Pin.
            this.firstView = view // Used for back's logic.

            this.active = true // Show edit panel.

            this.setView(view) // What edit view.
            this.updateState()
        },
        updateState() {
            this.save()
        },
        updatePinLocationData(lat, lng) {
            if(this.pin) {
                this.pin.latitude = lat.toString()
                this.pin.longitude = lng.toString()
                this.updateState()
                return true
            }
            return false
        },
        editPin(pin) {
            this.pin = pin
            this.updateState()
        },
        setPinName(name) {
            if (this.pin) this.pin.name = name             
            this.updateState()
        },
        setPinLatitude(lat) {
            if (this.pin) this.pin.latitude = lat
            this.updateState()
        },
        setPinLongitude(lng) {
            if (this.pin) this.pin.longitude = lng
            this.updateState()
        },
        setPinDescription(description) {
            if (this.pin) this.pin.description = description
            this.updateState()
        },
        setPinEmail(email) {
            if (this.pin) this.pin.email = email
            this.updateState()
        },
        setPinType(type) {
            if (this.pin) this.pin.type = type
            this.updateState()
        },
        setPinSensorData(data) {
            if (this.pin) this.pin.sensorData = data
            this.updateState()
        },
        setPinViewportData(data) {
            if (this.pin) this.pin.viewportData = data
            this.updateState()
        },
        storePin() {
            if (this.pin && this.farm) {
                if (!this.farm.pins) this.farm.pins = {}
                this.farm.pins[this.pin.id] = this.pin
                store.dispatch({ type: 'set-farm', payload: this.farm})
            }
        },
        deletePin() {
            if (!this.pin || !this?.farm?.pins || !(this.pin?.id in this.farm.pins) || !this.farm) return
            delete this.farm.pins[this.pin.id]
            store.dispatch({ type: 'set-farm', payload: this.farm})
            this.save()
        },
        disposePin() {
             //Dispose pin if closed and not created ?
            this.pin = null
            this.updateState()
        },
        close() {
            notifications.hide()
            this.active = false
            this.disposePin()
            this.updateState()
        },
        setView(view) { // Sets view, and notification message.
            this.view = view
            let message = 'Click where you want to place the new GeoAR Pin on the map'             
            if (view === 'type') message = 'Choose the type of GeoAR Pin you want to create'
            else if (view === 'data') message = 'Fill the GeoAR Pin details to finish'

            notifications.notify(message, false)            

            this.updateState()
        },
        save() { set({ ...this })}
    })

    useEffect(() => {
        set( {...state, farm: selectedFarm.farm })
    }, [selectedFarm.farm])

    return (
        <EditPin.Provider value={state}>
            { props.children }
        </EditPin.Provider>
    )
}

export default EditPin