import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Polygon } from '@react-google-maps/api'

import { IFarm, IPin } from '../store' 
import { useEditPin, useEvents, usePolygons, useSelectedFarm } from '../hooks'
import MapContainer from './MapContainer'

import Marker from '../components/Marker'

const Container = styled.div`
    background-color: ${p => p.theme.colors.dark};
    position: fixed;
    height: 100vh;
    width: 100vw;
`

interface IBoundPoint { lat: string, lng: string }

interface IMap {
    boundPoints?: IBoundPoint[],
    pins?: IPin[],
    setBoundPoints(bp: IBoundPoint[], pins: IPin[]): void,
    save(): void
}

export default function Map() {
    const events = useEvents()
    const editPin = useEditPin()
    const selectedFarm = useSelectedFarm()
    const polygons = usePolygons()

    const [state, set] = useState<IMap>({
        setBoundPoints(b, pins) {
            this.boundPoints = b
            this.pins = pins
            this.save()
        },
        save() { set({ ...this })}
    })

    function mapClickHandler(event): void {
        events.dispatch('map-click', {
            lat: event?.latLng?.lat(),
            lng: event?.latLng?.lng(),
        })
    }

    function getPathsNumber(paths) {
        return paths.map(l => {
            try {
                const lat = parseFloat(l.lat)
                const lng = parseFloat(l.lng)

                return {lat: lat, lng: lng}
            } catch {}
        })
    }

    useEffect(() => {
        const points = []
        const pins = []

        let latNumber = 0
        let lngNumber = 0
        let position = null

        if (selectedFarm?.farm?.pins) {
            Object.values(selectedFarm.farm.pins).forEach(p => {
                try {
                    latNumber = parseFloat(p.latitude)
                    lngNumber = parseFloat(p.longitude)
                    position = { lat: latNumber, lng: lngNumber } 
                    
                    if (latNumber && lngNumber) {
                        points.push(position)
                        pins.push(p)
                    }
                } catch { }
            })   
        }

        if (editPin.pin) {
            latNumber = parseFloat(editPin.pin.latitude)
            lngNumber = parseFloat(editPin.pin.longitude)
            position = { lat: latNumber, lng: lngNumber } 
            
            if (latNumber && lngNumber) {
                points.push(position)
                pins.push(editPin.pin)
            }
        }

        state.setBoundPoints(points, pins)

    }, [selectedFarm.farm, editPin?.pin?.latitude, editPin?.pin?.longitude])

    return (
        <Container>
            <MapContainer clickHandler={e => mapClickHandler(e)} boundPoints={state.boundPoints}>
                {state.boundPoints && state.boundPoints.map((bp, i) => (<Marker position={bp} pin={state.pins[i]} key={i}/>))}
                {Object.values(polygons.polygons).map(p => 
                    <Polygon 
                        key={p.id}
                        paths={getPathsNumber(p.paths)}
                        //@ts-ignore
                        options={p.options}
                    />)}
            </MapContainer>
        </Container>
    )
}