import React from 'react'
import styled, { keyframes } from 'styled-components'

import Logo from './Logo'

const Container = styled.div`
    position: relative;
    height: 100%;
`

const Animation = keyframes`
    0%, 100% { animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
    0% { 
        transform: rotateY(0deg) translateY(0) scale(0.7); 
    }
    50% { 
        transform: rotateY(1800deg) translateY(-1rem) scale(1);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); 
    }
    100% { 
        transform: rotateY(3600deg) translateY(0) scale(0.7); 
    }
`

const Circle = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;

    animation: ${Animation} 6.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
`

const Circle2 = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;

    animation: ${Animation} 6.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    animation-delay: .03s;

    div { background-color: ${p => p.theme.colors.alternative}; }
`

export default function Loading() {
    return (
        <Container>
            <Circle2> <Logo /> </Circle2>
            <Circle> <Logo /> </Circle>
        </Container>
    )
}