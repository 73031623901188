import React, { useState } from 'react'

import { key } from '../utility'
import { Dictionary } from '../store'

const Polygons = React.createContext({})

export interface ILocation {
    lat: string,
    lng: string
}

export interface IPolygonOptions {
    fillColor: string,
    fillOpacity: number,
    strokeColor: string,
    strokeOpacity: number,
    strokeWeight: number,
    clickable: boolean,
    draggable: boolean,
    editable: boolean,
    geodesic: boolean,
    zIndex: number
}

export interface IPolygon {
    id: string,
    paths: ILocation[],
    options: IPolygonOptions
}

const basicOptions: IPolygonOptions = {
    fillColor: "yellow",
    fillOpacity: .03,
    strokeColor: "#db9407",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}

export interface IPolygons {
    polygons: Dictionary<IPolygon>,
    addPoligon(paths: ILocation[]): IPolygon,
    removePoligon(id: string): void,
    clearAll(): void,
    save(): void
}

export function PolygonsContext(props: any) {
    const [state, set] = useState<IPolygons>({
        polygons: {},
        addPoligon(paths) {
            const poly: IPolygon = {
                id: key('poly'),
                options: basicOptions,
                paths: paths
            }

            this.polygons[poly.id] = poly
            this.save()

            return poly
        },
        removePoligon(poly) { },
        clearAll() {
            this.polygons = {}
            this.save()
        },
        save() { set({ ...this })}
    })

    return (
        <Polygons.Provider value={state}>
            { props.children }
        </Polygons.Provider>
    )
}

export default Polygons