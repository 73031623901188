import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import TopBar from './Users/TopBar'
import List from './Users/List'
import PermissionsPanel from './Users/PermissionPanel'

import { useStore, useRouter, useSelectedFarm } from '../hooks'
import { Paths } from '../context/RouterContext'

import { IUser } from '../context/AuthContext'
import { TRole } from '../store'

const Container = styled.div`
    position: fixed;
    top: 0; bottom: 0; right: 0; left: 0;
    background-color: ${p => p.theme.colors.light};
    padding: ${p => p.theme.padding};
`

export type TView = 'users' | 'invite'

interface IUsers {
    search: string,
    view: TView,
    selectedUser?: IUser,
}

export default function Users() {
    const router = useRouter()
    const store = useStore()
    const selectedFarm = useSelectedFarm()
    const [state, set] = useState<IUsers>({
        search: '',
        view: 'users'
    })

    const farm = selectedFarm.farm

    function backHandler(): void { router.setPath(Paths.home, true) }

    function setPermission(role: TRole): void {
        if (!state.selectedUser) return
        if (!farm.roles) farm.roles = {}

        farm.roles[state.selectedUser.localId] = role
        store.dispatch({type: 'set-farm', payload: farm})
    }

    function removeUser(): void {
        if (!state.selectedUser) return
        delete farm.roles[state.selectedUser.localId]
        store.dispatch({type: 'set-farm', payload: farm})
    }

    function isAdmin(): boolean {
        try { return farm.roles[store.state.user.localId] === 'admin' } catch {}
        return false
    }

    return (
        <Container>
            <TopBar 
                farm={farm}
                searchValue={state.search}
                setSearch={v => set(prev => { return {...prev, search: v}})}
                backHandler={() => backHandler()}
                view={state.view}
                setView={v => set({ ...state, view: v, search: ''})}
                isAdmin={isAdmin()}
            />
            <List 
                farm={farm}
                searchValue={state.search}
                view={state.view}
                selectUser={u => set(prev => { return {...prev, selectedUser: u}})}
                isAdmin={isAdmin()}
            />

            <PermissionsPanel 
                farm={farm}
                user={state.selectedUser}
                back={() => set(prev => { return {...prev, selectedUser: undefined}})}
                remove={() => removeUser()}
                setPermission={r => setPermission(r)}
            />
        </Container>
    )
}