import React, { useState } from 'react'

const Notifications = React.createContext({})

export interface INotifications {
    text: string,
    active: boolean,
    notify(text: string, timed?: boolean): void,
    hide(): void,
    save(): void
}

export function NotificationsContext(props: any) {
    const [state, set] = useState<INotifications>({
        text: '',
        active: false,
        notify(text, timed = true) {
            this.text = text 
            this.active = true
            if (timed) setInterval(() => {
                set(prev => { return {...prev, active: false} })
            }, 3000)
            this.save()
        },
        hide() {
            this.active = false
            this.save()
        },
        save() { set({ ...this })}
    })

    return (
        <Notifications.Provider value={state}>
            { props.children }
        </Notifications.Provider>
    )
}

export default Notifications