import React, { useState } from 'react'
import styled from 'styled-components'

import { IFarm } from '../../store'
import { TView } from '../Users'

import { Button, InputField, SVG } from '../../components/UI'
import Icons from '../../graphics/icons'

const TopBarContainer = styled.div`
    position: absolute;
    top: ${p => p.theme.padding};
    left: ${p => p.theme.padding};
    right: ${p => p.theme.padding};

    box-sizing: border-box;

    height: ${p => p.theme.minSize};

    display: grid;
    grid-template-columns: repeat(3, 1fr);
`

const TopBarLeft = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};

    grid-template-columns: ${p => p.theme.minSize} ${p => p.theme.minSize} auto;
`

const TopBarCenter = styled.div`
    display: grid;
    grid-template-columns: auto 20rem auto;
`

const TopBarRight = styled.div`
    display: grid;
    grid-template-columns: auto auto;
`

interface IProps {
    farm: IFarm,
    searchValue: string,
    setSearch(v: string): void,
    backHandler(): void,
    view: TView,
    setView(v: TView): void,
    isAdmin: boolean
}

export default function TopBar(props: IProps) {
    return (
        <TopBarContainer>
            <TopBarLeft>
                <Button light icon={Icons.back} onClick={() => props.backHandler()}/>
                <SVG dark contain image={Icons.logo}/>
                <Button light left onClick={() => {}}>{props?.farm?.name || 'Farm'}</Button>
            </TopBarLeft>
            <TopBarCenter>
                <div />
                <InputField 
                    value={props.searchValue}
                    onChange={v => props.setSearch(v)}
                    placeholder='Search User'
                />
                <div />
            </TopBarCenter>
            <TopBarRight>
                <div />
                {props.isAdmin && <Button onClick={() => {props.setView(props.view === 'users' ? 'invite' : 'users')}} reverse alternative left icon={Icons.profile}>
                    {props.view === 'users' ? 'Invite users to farm' : 'Check farm users'}
                </Button>}
            </TopBarRight>
        </TopBarContainer>
    )
}