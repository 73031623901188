import React, { useState, useEffect } from 'react'

import { IPin, TPin } from '../store'
import { useSelectedFarm } from '../hooks'

import { logEvent } from '../firebase/analytics'

const PinMenu = React.createContext({})

export interface IPinMenuState {
    selected?: IPin,
    setSelected(p: IPin): void,
    selectedType?: TPin,
    setSelectedType(t: TPin): void,
    pins: IPin[],
    save(): void
}

export function PinMenuContext(props: any) {
    const selectedFarm = useSelectedFarm()
    const [state, set] = useState<IPinMenuState>({
        setSelected(p) {
            this.selected = p
            if (this.selected && this.selectedType != this.selected.type) this.setSelectedType(this.selected.type)
            logEvent('Select Pin')
            this.save()
        },
        setSelectedType(t) {
            this.selectedType = t
            this.save()
        },
        pins: [],
        save() { set({ ...this })}
    })

    useEffect(() => {
        if (selectedFarm?.farm?.pins) set(prev => { return { ...prev, pins: Object.values(selectedFarm.farm.pins) }})
    }, [selectedFarm.farm])
    
    useEffect(() => {
        if (!state.selectedType || !selectedFarm?.farm?.pins) set(prev => { return { ...prev, pins: [] }})
        else set(prev => { return { ...prev, pins: Object.values(selectedFarm.farm.pins).filter(p => p.type === state.selectedType) }})
    }, [state.selectedType])

    return (
        <PinMenu.Provider value={state}>
            { props.children }
        </PinMenu.Provider>
    )
}

export default PinMenu