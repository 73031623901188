import React, { useEffect } from 'react'
import styled from 'styled-components'

import Icons from '../graphics/icons'
import { Button, Text } from '../components/UI'
import { useViewport } from '../hooks'

const Container = styled.div<{active?: boolean}>`
    display: ${p => p.active ? 'block': 'none'};
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;
    background-color: rgba(0, 0, 0, .8);
`

const Panel = styled.div<{active?: boolean}>`
    position: absolute;
    width: 90%;
    height: 70%;
    background-color: ${p => p.theme.colors.light};
    border-radius: ${p => p.theme.borderRadius};
    left: 50%;
    transform: translate(-50%, -50%);
    padding: ${p => p.theme.padding};

    opacity: ${p => p.active ? 1 : 0};
    top: ${p => p.active ? '50%' : '55%'};
    transition: .3s;
`

const TitleContent = styled.div`
    position: absolute;
    top: -${p => p.theme.padding};
    left: 0;
    transform: translateY(-100%);
    display: grid;
    grid-auto-flow: column;
    grid-gap: ${p => p.theme.gridGap};
`

export default function Viewport() {
    const viewport = useViewport()

    return (
        <Container active={viewport.state.active}>
            <Panel active={viewport.state.active}>
            <TitleContent><Button onClick={() => viewport.setActive(false)} icon={Icons.back}/><Text middle bold subTitle>{viewport.state.title || ''}</Text></TitleContent>

                {viewport.state.content}
            </Panel>
        </Container>
    )
}