import firebase from './'
import { key } from 'firebase-key'

import { storageUrl } from '../brand'

const storage = firebase.storage()

const maxMBUpload = 8

export const getUrl = (path: string) => new Promise(async (resolve, reject) => {
    try {
        // @ts-ignore
        path = path.replaceAll('/', '%2F') // Replace / from path.
        const url = `${storageUrl}${path}?alt=media`
        resolve(url)
    } catch (error) { reject(error) }
})

export const uploadImage = (file: any, name?: string) => new Promise(async (resolve, reject) => {
    if (file.size) {
        const mb = file.size / 1024 / 1024
        if (mb > maxMBUpload) {
            reject(`Image can't be larger than ${maxMBUpload} mbs`)
            return
        }
    }
    try {
        const id = name ? name : key() // Upload with given name, or generate random key.
        const snapshot = await storage.ref().child(id).put(file)
        resolve(id)
    } catch (error) { reject(error) }
})

export const uploadDownloadableFile = (file) => new Promise(async (resolve, reject) => {
    try {
        const name = file.name ? file.name : key()
        const path = `downloads/${name}`
        await storage.ref().child(path).put(file)
        const url = await getUrl(path)
        resolve(url)
    } catch (error) { reject(error) }
})