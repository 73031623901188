import React, { useEffect, useState } from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api'

const options = {
    mapTypeId: 'satellite',
    zoomControl: true,

    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,

    center: { lat: -35.009996, lng: 148.601852 },
    zoom: 10,
    maxZoom: 20,
    minZoom: 4,

    zoomControlOptions: {
        position: 6
    }
}

interface IMap {
    map?: any,
    setMap(m: any): void,
    save(): void
}

export default function MapContainer(props) { 
    const [state, set] = useState<IMap>({
        setMap(m) {
            this.map = m
            this.save()
        },
        save() { set({ ...this })}
    })

    useEffect(() => {
        if (!props.boundPoints) return
        try {
            // @ts-ignore
            const bounds = new window.google.maps.LatLngBounds()
            let count = 0
            props.boundPoints.forEach(p => {
                bounds.extend(p)
                count++
            })

            if (state.map && count > 1) state.map.fitBounds(bounds)
        } catch {}
    }, [props.boundPoints, state.map])

    return ( 
        <LoadScriptOnlyIfNeeded googleMapsApiKey='AIzaSyBv7A63xiHVHiuEdOHHfWMUTxAh1LxDxi4'>
            <GoogleMap
                mapContainerStyle={{
                    position: 'relative',
                    width: '100%',
                    height: '100%'
                }}
                onClick={e => props.clickHandler(e)}
                onLoad={map => state.setMap(map)}
                options={options}
            > 
                {props.children} 
            </GoogleMap>
        </LoadScriptOnlyIfNeeded>
    ) 
}

class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true
    const isBrowser = typeof document !== "undefined" // require('@react-google-maps/api/src/utils/isbrowser')
    // @ts-ignore
    const isAlreadyLoaded = window.google && window.google.maps && document.querySelector('body.first-hit-completed') // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error("google api is already presented")
        return
      }

      this.isCleaningUp().then(this.injectScript)
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true })
    }
  }
}