import React, { useReducer, useState, useEffect } from 'react'
import { getContent, subscribeToContent, setContent, Paths } from '../firebase/database'
import { key } from 'firebase-key'

import { IUser } from '../context/AuthContext'
import { ILocation } from '../context/PolygonsContext'

export interface Dictionary<T> { [Key: string]: T }

export type TRole = 'admin' | 'viewer' | 'manager'

export interface IBoundary {
    paths: ILocation[]
}

export interface IFarm {
    id: string,
    name: string,
    description: string,
    pins: Dictionary<IPin>,
    boundaries?: Dictionary<IBoundary>,
    public: boolean,
    roles: Dictionary<TRole>, // [ user's id, role's id ] 
    unity3dUrl?: string,
}

export interface IProperty {
    id: string,
    name: string,
    owners: Dictionary<string>, // User id's
}

export type TPin = 'note' | 'todo' | 'sensor' | '360image' | 'web'

export interface ISensorData {
    sensorApiUrl?: string,
    sensorSupplierUrl?: string,
}

export interface IViewportData {
    url?: string,
    title?: string,
}

export interface IPin {
    id: string,
    name?: string,
    description?: string,
    latitude?: string,
    longitude?: string,
    type?: TPin,
    image?: string,
    sensorData?: ISensorData,
    viewportData?: IViewportData,
}

export interface IState {
    user: IUser,
    users: Dictionary<IUser>,
    farms: Dictionary<IFarm>,
    properties: Dictionary<IProperty>
}

export interface IStore {
    state?: IState,
    dispatch?(action: IAction): void
}

const Store = React.createContext<IStore>({})

// Handle dictionary object changes.
function dictionaryReducer(databasePath: string, dictionary: Dictionary<any>, action: IAction): Dictionary<any> {
    dictionary = dictionary ? { ...dictionary } : { }
    if (!action.payload.id) return dictionary

    if (action.type.includes('set') && action.payload.id) { 
        dictionary[action.payload.id] = action.payload
        setContent(`${databasePath}/${action.payload.id}`, action.payload) 
    }

    if (action.type.includes('delete') && action.payload.id) {
        delete dictionary[action.payload.id]
        setContent(`${databasePath}/${action.payload.id}`, {}) 
    }

    return dictionary
}

interface IAction {
    type: 'init' | 'set-user' | 'set-property' | 'delete-property' | 'set-farm' | 'delete-farm'
    payload: any
}

function reducer(state: IState, action: IAction) {
    if (action.type === 'init') return action.payload

    if (action.type.includes('user')) return {...state, user: action.payload, users: dictionaryReducer(Paths.users, state.users, action)}

    if (action.type.includes('farm')) return {...state, farms: dictionaryReducer(Paths.farms, state.farms, action)}
    if (action.type.includes('property')) return {...state, properties: dictionaryReducer(Paths.properties, state.properties, action)}
    
    return state
}

export function StoreContext(props) {
    const [state, dispatch] = useReducer(reducer, {})

    return <Store.Provider value={{state: state, dispatch: dispatch }}>{ props.children }</Store.Provider>
}

export default Store