import React from 'react'
import styled, { css } from 'styled-components'

import { TRole, IFarm } from '../../store'
import { IUser } from '../../context/AuthContext'
import { Text, Button } from '../../components/UI'

import Icons from '../../graphics/icons'

const Container = styled.div<{show: boolean}>`
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, .7);

    ${p => !p.show && css` display: none; `}
`

const InnerPanel = styled.div<{show: boolean}>`
    position: absolute;
    width: 20rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: ${p => p.show ? 1 : 0};
    transition: .5s;
    transition-timing-function: ease;
    transform: translate(-50%, ${p => p.show ? '-50%' : '-40%'});

    border-radius: ${p => p.theme.borderRadius};
    overflow: hidden;
`

const InnerPanelTitle = styled.div`
    background-color: ${p => p.theme.colors.alternative};
    padding: ${p => p.theme.padding};
    display: grid;
    grid-template-columns: ${p => p.theme.minSize} auto min-content;
`

const InnerPanelWhite = styled.div`
    background-color: ${p => p.theme.colors.light};
    padding: ${p => p.theme.padding};
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

interface IProps {
    user: IUser,
    farm: IFarm,
    setPermission(r: TRole): void,
    remove(): void,
    back(): void
}

export default function PermissionPanel(props: IProps) {
    const show = props.user != undefined
    
    function permissionHandler(role: TRole): void {
        props.setPermission(role)
        if (isNew()) props.back()
    }

    function remove(): void {
        props.remove()
        props.back()
    }
    
    function isSelected(role: TRole): boolean {
        try { if (props?.user?.localId in props?.farm?.roles) return props.farm.roles[props.user.localId] === role } catch {}
        return false
    }
    
    function isNew(): boolean {
        try { return !(props?.user?.localId in props?.farm?.roles) }
        catch { return true }
    }

    return (
        <Container show={show}>
            <InnerPanel show={show}>
                <InnerPanelTitle>
                    <Button icon={Icons.back} onClick={() => props.back()}/>
                    <div />
                    <Text oneline bold middle>{`${props?.user?.firstName} ${props?.user?.lastName}`}</Text>
                </InnerPanelTitle>
                <InnerPanelWhite>
                    <Text dark bold>Permissions</Text>
                    <Button reverse left onClick={() => permissionHandler('admin')} light={!isSelected('admin')} icon={Icons.key}>Admin</Button>
                    <Button reverse left onClick={() => permissionHandler('viewer')} light={!isSelected('viewer')} icon={Icons.eye}>Viewer</Button>
                    <Button reverse left onClick={() => permissionHandler('manager')} light={!isSelected('manager')} icon={Icons.manager}>Manager</Button>
                    <div />
                    {!isNew() && <Button alternative onClick={() => remove()}>Remove user</Button>}
                </InnerPanelWhite>
            </InnerPanel>
        </Container>
    )
}
