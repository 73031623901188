import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// Theme.
import { ThemeProvider } from 'styled-components'
import Theme from './theme'

function Container() {
    return (
        <ThemeProvider theme={ Theme }>
            <App />
        </ThemeProvider>
    )
}

ReactDOM.render(<React.StrictMode><Container /></React.StrictMode>, document.getElementById('root'))