import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

const Router = React.createContext({})

export enum Paths {
    home = '/',
    login = '/login',
    users = '/users',
    settings = '/settings'
}

export interface IRouter {
    path: Paths,
    setPath(path: Paths, keepParams?: boolean): void,
    getParam(param: string): string,
    setParams(params: string): void,
    back(): void,
    save(): void
}

export function RouterContext(props: any) {
    const history = useHistory()
    const [state, set] = useState<IRouter>({
        path: Paths.home,
        setPath(path, keepParams = false) {
            this.path = path
            if (keepParams) this.path += history.location.search
            history.push(this.path)
            this.save()
        },
        getParam(param) {
            try { return new URLSearchParams(history.location.search).get(param) }
            catch { return '' }
        },
        setParams(params) { history.push(`${history.location.pathname}?${params}`) },
        back() { history.goBack() },
        save() { set({ ...this })}
    })

    return (
        <Router.Provider value={state}>
            { props.children }
        </Router.Provider>
    )
}

export default Router