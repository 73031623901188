import { IPin, Dictionary } from '../store'
import { key } from './'

const JSZip = require('jszip')
const tj = require('@tmcw/togeojson')
const DOMParser = require('xmldom').DOMParser
const reader = new FileReader()

function getPins(kml): Dictionary<IPin> {
    const pins: Dictionary<IPin> = {} 

    if (kml?.features) {
        for (const feature of kml.features) {
            if (feature?.geometry?.coordinates) {
                const newPin: IPin = {
                    id: key('pin'),
                    longitude: feature.geometry.coordinates[0],
                    latitude: feature.geometry.coordinates[1],
                    type: 'note'
                }

                if (feature?.properties?.name) newPin.name = feature.properties.name

                if (newPin.longitude && newPin.latitude) pins[newPin.id] = newPin
            }
        }
    }

    return pins
}

function parseKml(text) {
    const kml = new DOMParser().parseFromString(text)
    const converted = tj.kml(kml)

    return getPins(converted)
}

export default function getPinsFromKMZ(file): Promise<Dictionary<IPin>> {
    return new Promise((resolve, reject) => {
        try {
            if (file.name.toLowerCase().includes('.kmz')) JSZip.loadAsync(file).then(function(zip) { 
                zip.forEach(function (relativePath, file) {
                    file.async('string').then(value => { 
                        resolve(parseKml(value))
                    })
                })
            })

            else {
                reader.onload = function(event) {
                    resolve(parseKml(event.target.result))
                }
            }
        } catch(error) { reject(error) }
    })
}