import React, { useState } from 'react'
import styled from 'styled-components'

import { Button } from '../../components/UI'
import PinPanel from '../../components/PinPanel'
import Icons from '../../graphics/icons'

import { TPin, IPin, IFarm } from '../../store'
import { usePinMenu } from '../../hooks'

const Container = styled.div<{show: boolean}>`
    position: fixed;
    top: ${p => p.theme.padding};
    bottom: ${p => p.theme.padding};

    display: grid;
    grid-template-columns: min-content 20rem;
    grid-gap: 2rem;

    right: ${p => p.show ? p.theme.padding : '-21rem'};
    transition: .3s;
    pointer-events: none;
`

interface IPinMenuProps {
}

export default function PinMenu(props: IPinMenuProps) {
    const pinMenu = usePinMenu()

    return (
        <Container show={pinMenu.selectedType != null && pinMenu.pins.length != 0}>
            <Buttons 
                selected={pinMenu.selectedType}
                setSelectedType={t => pinMenu.setSelectedType(t)}
            />
            <Cards 
                pins={pinMenu.pins}
                selected={pinMenu.selected}
                setSelected={p => pinMenu.setSelected(p)}
            />
        </Container>
    )
}

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    pointer-events: none;
`

const ButtonsBlock = styled.div`
    display: grid;
    grid-gap: .5rem;
    pointer-events: all;
`

interface IButtonsProps {
    selected?: TPin,
    setSelectedType(t: TPin): void
}

function Buttons(props: IButtonsProps) {
    function clickHandler(type: TPin) {
        if (props.selected === type) props.setSelectedType(null)
        else props.setSelectedType(type)
    }

    const typesData = [
        { type: 'todo', icon: Icons.task, name: 'Task' },
        { type: 'note', icon: Icons.note, name: 'Note' },
        { type: 'sensor', icon: Icons.sensor, name: 'Sensor' },
        { type: '360image', icon: Icons.image360, name: '360 Image' },
        { type: 'web', icon: Icons.web, name: 'Web' },
    ]

    return (
        <ButtonsContainer>
            <ButtonsBlock>
                {typesData.map((data, i) => 
                    <Button light={props.selected === data.type} reverse icon={data.icon} onClick={() => clickHandler(data.type as TPin)} alternative={i%2===0}>{data.name}</Button>)}
            </ButtonsBlock>
        </ButtonsContainer>
    )
}

const CardsContainer = styled.div`
    overflow: auto;
    border-radius: ${p => p.theme.borderRadius};
    pointer-events: all;
`

const CardsGrid = styled.div`
    display: grid;
    margin-left: .5rem;
    grid-gap: ${p => p.theme.gridGap};
`

interface ICardsProps {
    pins: IPin[],
    selected: IPin,
    setSelected(p: IPin): void
}

function Cards(props: ICardsProps) {
    function selectHandler(pin: IPin) {
        if (pin.id === props?.selected?.id) props.setSelected(null)
        else props.setSelected(pin)
    }

    return (
        <CardsContainer>
            <CardsGrid>
                {props.pins && props.pins.map(p => <PinPanel selectHandler={p => selectHandler(p)} selected={props?.selected?.id === p.id} pin={p} key={p.id} />)}
            </CardsGrid>
        </CardsContainer>
    )
}