import React, { useState, useEffect, useRef } from 'react'
import { logIn, trackAuthState, register, logOut as firebaseLogOut } from '../firebase/auth'
import { getContent, setContent, onDisconnect, subscribeToContent, Paths, filterByIncludes, getFiltered } from '../firebase/database'
import { Paths as routerPaths } from '../context/RouterContext'

import { useRouter, useStore } from '../hooks'
import { IStore, Dictionary } from '../store'

import { logEvent } from '../firebase/analytics'

const Auth = React.createContext({})

export interface IUser {
    localId?: string,
    email: string,
    firstName?: string,
    lastName?: string,
    country?: string,
}

export interface IAuth {
    user?: IUser,
    logIn(email: string, password: string): Promise<IUser>,
    register(user: IUser, password: string): Promise<IUser>,
    logOut(): void,
    loginCallback?(user: IUser): void,
    isTrackingAuthState?: Boolean,
    save(): void
}

export function AuthContext(props) {
    const router = useRouter()
    const store = useStore()
    const [state, set] = useState<IAuth>({
        logIn(email, password) {
            return new Promise(async (resolve, reject) => {
                try {
                    this.loginCallback = function(user: IUser) { resolve(user) }
                    this.save()
                    await logIn(email, password)
                } catch (error) { reject(error) }
            })
        },
        logOut() {
            delete this.user
            this.isTrackingAuthState = false
            firebaseLogOut()
            this.save()
        },
        register(user, password) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await register(user.email, password) as any

                    user.localId = response.user.uid

                    logEvent('Register')

                    await setContent(`${Paths.users}/${user.localId}`, user)

                    await state.logIn(user.email, password)

                    resolve(user)
                } catch(error) {reject (error)}
            })
        },
        save() { set({ ...this })}
    })

    const routerRef = useRef(router)
    const stateRef = useRef(state)
    routerRef.current = router
    stateRef.current = state

    useEffect(() => { 
        const timeout = setTimeout(function () {
            const router = routerRef.current
            const state = stateRef.current

            if (router.path != routerPaths.login && (!state.user || !state.user.localId)) 
                router.setPath(routerPaths.login) 
            

        }.bind(this), 2500)

        return () => clearTimeout(timeout)

    }, [state.user, router.path])

    useEffect(() => {
        async function handleStateChange(localUser) {
            if (localUser) {
                let user: IUser = await getContent(`${Paths.users}/${localUser.uid}`) as IUser

                // If there is no database user, create it.
                if (!user) user = {
                    email: localUser.email,
                    localId: localUser.uid
                }

                // Save email if database's user doesn't have it.
                else if (!user.email) user.email = localUser.email

                // Set user.
                set({ ...state, user: user, isTrackingAuthState: true })
                store.dispatch({ type: 'set-user', payload: user })
                initUserData(store, user)

                // Call callback.
                if (state.loginCallback) state.loginCallback(user)
                if (router.path === routerPaths.login) router.setPath(routerPaths.home)

                // Log Event.
                logEvent('Login')
            }

            else  set({ ...state, user: null, isTrackingAuthState: true }) 
        }

        if (!state.isTrackingAuthState) trackAuthState(handleStateChange)
        
    }, [state.isTrackingAuthState])

    return <Auth.Provider value={state}>{ props.children }</Auth.Provider>
}

async function initUserData(store: IStore, user: IUser): Promise<void> {
    const properties = await filterByIncludes(Paths.properties, 'owners', [user.localId])
    const farms = await filterByIncludes(Paths.farms, 'roles', [user.localId])
    const publicFarms = await getFiltered(Paths.farms, 'public', true)
    const users = await getContent(Paths.users) as Dictionary<IUser>

    // Add public farms.
    Object.entries(publicFarms).forEach(([k, v]) => { farms[k] = v })

    store.state.properties = properties
    store.state.farms = farms
    store.state.user = user
    store.state.users = users

    store.dispatch({ type: 'init', payload: store.state })
}

export default Auth